import { FETCH_ESTABELECIMENTO_DATA, FETCH_ESTABELECIMENTO_FAILURE, FETCH_ESTABELECIMENTO_SUCCESS } from './types';

export const fetchEstabelecimento = (payload: { empresa_id: number }) => ({
  type: FETCH_ESTABELECIMENTO_DATA,
  payload,
});

export const fetchEstabelecimentoSuccess = (data: any[]) => ({
  type: FETCH_ESTABELECIMENTO_SUCCESS,
  data,
});

export const fetchEstabelecimentoFailure = (error: any) => ({
  type: FETCH_ESTABELECIMENTO_FAILURE,
  payload: error,
});
