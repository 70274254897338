import { Button, Form, Modal } from 'antd';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

const commonFontStyles = `
  font-family: 'Poppins', sans-serif !important;
`;

const commonInputStyles = `
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  margin: 0px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.88);
  font-size: 10px;
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 700px;
    width: auto;
    height: auto;
    left: -20%;
    transform: translateY(-5%);
    padding: 0;
    background: linear-gradient(135deg, #536390, #252f64) !important;
  }

  .ant-picker-input input,
  .ant-select-selection-placeholder {
    font-size: 12px !important;
    ${commonFontStyles}
    color: rgba(0, 0, 0, 0.45) !important;
  }

  .ant-select-selector {
    border-radius: 5px;
    border: 1px solid #000;
  }

  .ant-modal-body {
    width: 100%;
    height: 100%;
  }

  .ant-modal-body-custom {
    padding: 10px 30px;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
  }

  .custom-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
  }

  .ant-modal-close {
    color: #fff;
  }

  .ant-modal-close:hover {
    background: #fff;
  }

  .modal-header-title {
    left: 35%;
    transform: translateX(-35%);
    font-size: 18px;
    text-align: center;
    ${commonFontStyles}
    color: #fff;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .ant-modal-content {
      min-width: 90%;
      left: 0;
    }
  }
`;

export const CustomConteinerFormModal = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    font-size: 14px;
    text-align: center;
    margin: 0px;
    ${commonFontStyles}
    font-weight: bold;
  }

  .ant-form-item .ant-form-item-label > label {
    font-size: 11px;
    ${commonFontStyles}
  }
`;

export const CustomConteinerFormModalDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;

  .ant-form-item {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .ant-form-item-control {
    position: relative;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonConcluir = styled(Button)`
  padding: 10px 20px !important;
  background: linear-gradient(135deg, #536390, #252f64) !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  justify-content: center;

  &:hover,
  &:focus {
    background: linear-gradient(135deg, #252f64, #536390) !important;
    color: #f0f0f0 !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important;
  }

  .anticon {
    margin-right: 8px !important;
  }
`;

export const InputMaskStyled = styled(ReactInputMask)`
  ${commonInputStyles}
  ${commonFontStyles}
  background: #ffffff;

  &:focus {
    border-color: #536390;
  }
`;

export const CustomFooterButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 15px;

  button {
    min-width: 300px;
    width: auto;
    padding: 10px 30px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    button {
      width: 100%;
      min-width: unset;
      padding: 10px 20px;
    }
  }
`;
