import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  PROPOSTA,
  PROPOSTA_SUCCESS,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  UPLOAD_USER_PHOTO
} from './types';

export const fetchUserProfile = () => ({
  type: FETCH_USER_PROFILE,
});

export const fetchUserProfileSuccess = (profile: any) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: profile,
});

export const fetchUserProfileFailure = (error: any) => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: error,
});

export const recoverPassword = (subdomain: string, email: string) => ({
  type: RECOVER_PASSWORD,
  payload: { subdomain, email },
});

export const recoverPasswordSuccess = () => ({
  type: RECOVER_PASSWORD_SUCCESS,
});

export const resetPassword = (subdomain: string, token: string | null, password: string) => ({
  type: RESET_PASSWORD,
  payload: { subdomain, token, password },
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const proposal = (proposal: any) => ({
  type: PROPOSTA,
  payload: { proposal },
});

export const proposalSuccess = () => ({
  type: PROPOSTA_SUCCESS,
});

export const uploadUserPhoto = (file: any) => ({
  type: UPLOAD_USER_PHOTO,
  payload: { file },
});
