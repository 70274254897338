import { Col, message, Row, Skeleton } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import { StatCard } from '../../components/Charts/StatCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { DollarCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { DropDownContent } from '../../components/DropDownContent';
import { fetchPagamentoConsolidado, fetchPagamentoConsolidadoPdf, fetchPagamentoConsolidadoXls } from '../../store/states/pagamentoConsolidado/actions';
import CollapseFilter from '../../components/CollapseFilter';
import { useEffect, useState } from 'react';
import { mapFiltersAgendaRecebimentosForApi, mapFiltersPagamentosConsolidadosForApi } from '../../utils/validateFilter';
import TableComponent from '../../components/TablePagination';
import { columnsPagamentoConsolidado } from './interfaces/columnsPagamentosConsolidados';
import { Container } from '../../styles/GlobalStyles';
import MessageHeader from '../../components/MessageHeader';
import { renderPagamentoConsolidado, textPagamentoConsolidado } from './interfaces';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';

function PagamentoConsolidadoScreen() {
  const dispatch = useDispatch();
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerPagamentosConsolidados');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [selectedAdquirentes, setSelectedAdquirentes] = useState<string[]>([]);
  const [selectedBanco, setSelectedBanco] = useState(null);
  const [perPage] = useState(10);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [pagamentosTotal, setPagamentosTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const loading = useSelector((state: RootState) => state.pagamentoConsolidado.loading);
  const pagamentosConsolidados = useSelector((state: RootState) => state.pagamentoConsolidado.data);
  const pagamentosConsolidadosFiltroData = useSelector((state: RootState) => state.pagamentoConsolidado.consolidadosFiltroData);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const loadingConsolidadoExport = useSelector((state: RootState) => state.pagamentoConsolidado.loadingConsolidadoExport);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const adquirentesAtivos = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.ADQUIRENTENOME) || []);
  const adquirentesFiltradas = adquirentes ? adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.adquirentenome)) : [];
  const bancosAtivos = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.BANCONOME) || []);
  const bancosFiltrados = bancos ? bancos.filter((banco: any) => bancosAtivos.has(banco.banconome)) : [];
  const bandeirasAtivas = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.BANDEIRANOME) || []);
  const bandeirasFiltradas = bandeiras ? bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.bandeiranome)) : [];
  const empresas = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.EMPRESANOME) || []);
  const contas = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.CONTABANCARIA) || []);
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setPagamentosTotal(pagamentosConsolidados.totalList);
    setTotalLiquidoSubFiltro(pagamentosConsolidados.totalValorLiquido);
    setTotalBrutoSubFiltro(pagamentosConsolidados.totalValorBruto);
  }, [pagamentosConsolidados]);

  useEffect(() => {
    localStorage.setItem('showAcquirerPagamentosConsolidados', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  const handleExportXls = () => {
    message.success('Gerando o relatório de recebíveis no formato .xlsx, aguarde...');
    dispatch(
      fetchPagamentoConsolidadoXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleExportPdf = () => {
    message.success('Gerando o relatório de recebíveis no formato .pdf, aguarde...');
    dispatch(
      fetchPagamentoConsolidadoPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleAdquirenteSelect = (adquirenteName: any) => {
    let newSelectedAdquirentes = [...selectedAdquirentes];
    if (newSelectedAdquirentes.includes(adquirenteName)) {
      newSelectedAdquirentes = newSelectedAdquirentes.filter(name => name !== adquirenteName);
    } else {
      newSelectedAdquirentes.push(adquirenteName);
    }
    setSelectedAdquirentes(newSelectedAdquirentes);
    const newFilters = mapFiltersPagamentosConsolidadosForApi({ ADQUIRENTENOME: newSelectedAdquirentes });
    setFilters(newFilters);
    fetchConsolidados(currentPage, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', newFilters);
    setActiveFilter(newSelectedAdquirentes.join(', '));
  };

  const handleBancoSelect = (bancoName: any) => {
    if (selectedBanco === bancoName) {
      setSelectedBanco(null);
      setActiveFilter(null);
      fetchConsolidados(1, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', mapFiltersAgendaRecebimentosForApi({ BANCONOME: null }));
    } else {
      const newFilters = selectedBanco === bancoName ? {} : mapFiltersAgendaRecebimentosForApi({ BANCONOME: bancoName });
      setFilters(newFilters);
      fetchConsolidados(currentPage, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', newFilters);
      setSelectedBanco(bancoName);
      setActiveFilter(bancoName);
      setSelectedAdquirentes([]);
    }
  };

  const fetchConsolidados = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersPagamentosConsolidadosForApi(filters);
    dispatch(
      fetchPagamentoConsolidado({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'PAGAMENTODATAPAGAMENTO', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchConsolidados(page, pageSize, sortField, sortOrder, filters);
  };

  const handleRefresh = () => {
    localStorage.removeItem('showAcquirerPagamentosConsolidados');
    setFilters({});
    setSelectedAdquirentes([]);
    setSelectedBanco(null);
    setActiveFilter(null);
    setCurrentPage(1);
    setShowAcquirer(true);
    setResetTableFilters(prev => !prev);
    fetchConsolidados(1, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', {});
    setTableKey(prevKey => prevKey + 1);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textPagamentoConsolidado} />
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard
                loadingData={loading}
                title="Bruto"
                value={(pagamentosConsolidados.totalValorBruto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loading}
                title="Líquido"
                value={(pagamentosConsolidados.totalValorLiquido || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
          </Row>
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <CollapseFilter
                isCollapseOpen={isCollapseOpen}
                setIsCollapseOpen={setIsCollapseOpen}
                setShowAcquirer={setShowAcquirer}
                showAcquirer={showAcquirer}
                handleAdquirenteSelect={handleAdquirenteSelect}
                handleBancoSelect={handleBancoSelect}
                activeFilter={activeFilter}
                adquirentes={adquirentesFiltradas}
                bancos={bancosFiltrados}
                selectedAdquirentes={selectedAdquirentes}
                selectedBanco={selectedBanco}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10, gap: 5 }}>
                {pagamentosConsolidados.list &&
                  <ButtonRefreshList loading={loading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                    Limpar Filtros
                  </ButtonRefreshList>}
                {pagamentosConsolidados.list && pagamentosConsolidados.list.length !== 0 &&
                  <DropDownContent loadingExport={loadingConsolidadoExport} transacoes={pagamentosConsolidados} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
              </div>
            </>
          )}
          <TableComponent
            key={tableKey}
            loadingData={loading}
            data={pagamentosConsolidados.list && pagamentosConsolidados.list.length > 0 ? pagamentosConsolidados.list : []}
            columns={columnsPagamentoConsolidado(Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(empresas), Array.from(contas), selectedAdquirentes)}
            rowKeyId="pagamentoid"
            rowRender={renderPagamentoConsolidado}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={pagamentosTotal}
            onChangePage={handlePageChange}
            expand={true}
            externalFilters={filters}
            totalBrutoSubFiltro={totalBrutoSubFiltro}
            totalLiquidoSubFiltro={totalLiquidoSubFiltro}
            resetFilters={resetTableFilters}
            updateFilters={updateFilters}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default PagamentoConsolidadoScreen;
