import { Card, Col, Row } from 'antd';
import {
  AimOutlined,
  ApiOutlined,
  BankOutlined,
  CalendarOutlined,
  CaretUpOutlined,
  CarryOutOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  CrownOutlined,
  DeliveredProcedureOutlined,
  DollarOutlined,
  EyeOutlined,
  FallOutlined,
  FieldNumberOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FolderViewOutlined,
  HomeOutlined,
  KeyOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PercentageOutlined,
  SafetyCertificateOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import moment from 'moment';

export const renderVenda = (record: any) => {
  return (
    <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
      <div style={{ marginBottom: 15 }}>
        <CaretUpOutlined style={{ marginRight: 10 }} />
        <strong>Transação de Venda</strong>
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <div>
            <ClockCircleOutlined style={{ marginRight: 10 }} />
            <span>Data: </span>
            <strong>{moment(record.VENDADATA).format('DD/MM/YYYY')}</strong>
          </div>
          <div>
            <FieldTimeOutlined style={{ marginRight: 10 }} />
            <span>Hora: </span>
            <strong>{record.VENDAHORA}</strong>
          </div>
          <div>
            <CalendarOutlined style={{ marginRight: 10 }} />
            <span>Previsão Pagamento: </span>
            <strong>{record.VENDADATAPREVISAO ? moment(record.VENDADATAPREVISAO).format('DD/MM/YYYY') : 'N/A'}</strong>
          </div>
          <div style={{ marginTop: 10 }}>
            <CrownOutlined style={{ marginRight: 10 }} />
            <span>Empresa: </span>
            <strong>{record.EMPRESANOME}</strong>
          </div>
          <div>
            <HomeOutlined style={{ marginRight: 10 }} />
            <span>Estabelecimento: </span>
            <strong>{record.ESTABELECIMENTONUMERO}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <FileDoneOutlined style={{ marginRight: 10 }} />
            <span>Banco: </span>
            <strong>{record.BANCONOME}</strong>
          </div>
          <div>
            <FileDoneOutlined style={{ marginRight: 10 }} />
            <span>Agência: </span>
            <strong>{record.VENDAAGENCIA}</strong>
          </div>
          <div>
            <FolderViewOutlined style={{ marginRight: 10 }} />
            <span>Conta: </span>
            <strong>{record.VENDACONTA}</strong>
          </div>
          <div>
            <WalletOutlined style={{ marginRight: 10 }} />
            <span>Adquirente: </span>
            <strong>{record.ADQUIRENTENOME}</strong>
          </div>
          <div>
            <BankOutlined style={{ marginRight: 10 }} />
            <span>Bandeira: </span>
            <strong>{record.BANDEIRANOME}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <CreditCardOutlined style={{ marginRight: 10 }} />
            <span>Cartão: </span>
            <strong>{record.VENDACARTAONUMERO}</strong>
          </div>
          <div>
            <SafetyCertificateOutlined style={{ marginRight: 10 }} />
            <span>Autorização: </span>
            <strong>{record.VENDAAUTORIZACAO}</strong>
          </div>
          <div>
            <KeyOutlined style={{ marginRight: 10 }} />
            <span>NSU: </span>
            <strong>{record.VENDANSU}</strong>
          </div>
          <div>
            <ApiOutlined style={{ marginRight: 10 }} />
            <span>TID: </span>
            <strong>{record.VENDATID}</strong>
          </div>
          <div>
            <PaperClipOutlined style={{ marginRight: 10 }} />
            <span>RO: </span>
            <strong>{record.VENDARO}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <DeliveredProcedureOutlined style={{ marginRight: 10 }} />
            <span>Modalidade: </span>
            <strong>{record.MODALIDADENOME}</strong>
          </div>
          <div>
            <FieldNumberOutlined style={{ marginRight: 10 }} />
            <span>Valor Bruto: </span>
            <strong>
              R${' '}
              {record.VENDAVALORBRUTO.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).replace('.', ',')}
            </strong>
          </div>
          <div>
            <PercentageOutlined style={{ marginRight: 10 }} />
            <span>Taxa(%): </span>
            <strong>{record.VENDATAXA} %</strong>
          </div>
          <div>
            <FallOutlined style={{ marginRight: 10 }} />
            <span>Valor Desconto: </span>
            <strong>
              R${' '}
              {record.VENDAVALORDESCONTO.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).replace('.', ',')}
            </strong>
          </div>
          <div>
            <DollarOutlined style={{ marginRight: 10 }} />
            <span>Valor Líquido: </span>
            <strong>
              R${' '}
              {record.VENDAVALORLIQUIDO.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).replace('.', ',')}
            </strong>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ marginTop: 10 }}>
            <PartitionOutlined style={{ marginRight: 10 }} />
            <span>Total Parcela(s): </span>
            <strong>{record.VENDATOTALPARCELA}</strong>
          </div>
          <div>
            <AimOutlined style={{ marginRight: 10 }} />
            <span>Parcela: </span>
            <strong>{record.VENDAPARCELA}</strong>
          </div>
          <div>
            <ApiOutlined style={{ marginRight: 10 }} />
            <span>Terminal: </span>
            <strong>{record.VENDATERMINAL ? record.VENDATERMINAL : 'N/A'}</strong>
          </div>
        </Col>
        <Col span={6} style={{ marginTop: 10 }}>
          <div>
            <EyeOutlined style={{ marginRight: 10 }} />
            <span>Status da transação: </span>
            <strong>
              {record.VENDASTATUSCONCILIACAO === 0 ? 'NÃO CONCILIADO' :
                record.VENDASTATUSCONCILIACAO === 1 ? 'CONCILIADO' :
                  record.VENDASTATUSCONCILIACAO === 2 ? 'ATRASADO' : 'STATUS DESCONHECIDO'}
            </strong>
          </div>
          <div>
            <CarryOutOutlined style={{ marginRight: 10 }} />
            <span>Data Pagamento: </span>
            <strong>
              {record.VENDADATAPAGAMENTO ? moment(record.VENDADATAPAGAMENTO).format('DD/MM/YYYY') : 'N/A'}
            </strong>
          </div>
          <div>
            <DollarOutlined style={{ marginRight: 10 }} />
            <span>Valor Pago: </span>
            <strong>
              {record.VENDAVALORPAGO ? (
                <strong>
                  R${' '}
                  {record.VENDAVALORPAGO.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).replace('.', ',')}
                </strong>
              ) : (
                'N/A'
              )}
            </strong>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
