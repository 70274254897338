import { 
    FETCH_BANKING_OFX_DATA, 
    FETCH_BANKING_OFX_FAILURE, 
    FETCH_BANKING_OFX_SUCCESS, 
    FETCH_UPLOAD_OFX_DATA, 
    FETCH_UPLOAD_OFX_FAILURE, 
    FETCH_UPLOAD_OFX_SUCCESS, 
    RESET_UPLOAD_STATUS,
    FETCH_BANKING_OFX_PDF,
    FETCH_BANKING_OFX_PDF_SUCCESS,
    FETCH_BANKING_OFX_PDF_FAILURE,
    FETCH_BANKING_OFX_XLS,
    FETCH_BANKING_OFX_XLS_SUCCESS,
    FETCH_BANKING_OFX_XLS_FAILURE,
} from './types';

export const fetchBankingOfx = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
  type: FETCH_BANKING_OFX_DATA,
  payload,
});

export const fetchBankingOfxSuccess = (data: any) => ({
  type: FETCH_BANKING_OFX_SUCCESS,
  payload: data,
});

export const fetchBankingOfxFailure = (error: any) => ({
  type: FETCH_BANKING_OFX_FAILURE,
  payload: error,
});

export const fetchUploadOfx = (file: File) => ({
  type: FETCH_UPLOAD_OFX_DATA,
  payload: { file }
});

export const fetchUploadOfxSuccess = (file: File, status: boolean, data: any) => ({
  type: FETCH_UPLOAD_OFX_SUCCESS,
  payload: {status, data},
  meta: { file }
});

export const fetchUploadOfxFailure = (file: File, error: any) => ({
  type: FETCH_UPLOAD_OFX_FAILURE,
  payload: error,
  meta: { file }
});

export const resetUploadStatus = () => ({
    type: RESET_UPLOAD_STATUS,
});

export const fetchBankingOfxPdf = (payload: { start_date: string; end_date: string }) => ({
  type: FETCH_BANKING_OFX_PDF,
  payload,
});

export const fetchBankingOfxPdfSuccess = () => ({
  type: FETCH_BANKING_OFX_PDF_SUCCESS,
});

export const fetchBankingOfxPdfFailure = (error: any) => ({
  type: FETCH_BANKING_OFX_PDF_FAILURE,
  payload: error,
});

export const fetchBankingOfxXls = (payload: { start_date: string; end_date: string }) => ({
  type: FETCH_BANKING_OFX_XLS,
  payload,
});

export const fetchBankingOfxXlsSuccess = () => ({
  type: FETCH_BANKING_OFX_XLS_SUCCESS,
});

export const fetchBankingOfxXlsFailure = (error: any) => ({
  type: FETCH_BANKING_OFX_XLS_FAILURE,
  payload: error,
});