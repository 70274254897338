import { call, put, takeLatest } from 'redux-saga/effects';
import { 
    pagamentoConsolidadoApi,
    pagamentoConsolidadoFiltroApi,
    pagamentoConsolidadoPdfApi,
    pagamentoConsolidadoXlsApi
 } from '../../target-api/api';
import { fetchPagamentoConsolidadoFailure, fetchPagamentoConsolidadoFilterDataFailure, fetchPagamentoConsolidadoFilterDataSuccess, fetchPagamentoConsolidadoPdfFailure, fetchPagamentoConsolidadoPdfSuccess, fetchPagamentoConsolidadoSuccess, fetchPagamentoConsolidadoXlsFailure, fetchPagamentoConsolidadoXlsSuccess } from './actions';
import { message } from 'antd';
import { FETCH_PAGAMENTOS_CONSOLIDADOS_DATA, FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA, FETCH_PAGAMENTOS_CONSOLIDADOS_PDF, FETCH_PAGAMENTOS_CONSOLIDADOS_XLS } from './types';

export function* fetchPagamentoConsolidadoSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const pagamentoConsolidadoResponse = yield call(pagamentoConsolidadoApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder);
    if (pagamentoConsolidadoResponse && pagamentoConsolidadoResponse.length <= 0) {
      message.warning('Nenhum pagamento encontrado!');
      yield put(fetchPagamentoConsolidadoSuccess([]));
    } else {
      yield put(fetchPagamentoConsolidadoSuccess(pagamentoConsolidadoResponse));
    }
  } catch (error: any) {
    yield put(fetchPagamentoConsolidadoFailure(error.message));
  }
}

export function* fetchPagamentoConsolidadoFiltrosSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const pagamentoConsolidadoResponse = yield call(pagamentoConsolidadoFiltroApi, company_id, start_date, end_date, page, pageSize, sortField, sortOrder);
    if (pagamentoConsolidadoResponse && pagamentoConsolidadoResponse.length <= 0) {
      message.warning('Nenhum pagamento encontrado!');
      yield put(fetchPagamentoConsolidadoFilterDataSuccess([]));
    } else {
      yield put(fetchPagamentoConsolidadoFilterDataSuccess(pagamentoConsolidadoResponse));
    }
  } catch (error: any) {
    yield put(fetchPagamentoConsolidadoFilterDataFailure(error.message));
  }
}

export function* fetchPagamentoConsolidadoPdfSaga(action: any): Generator<any, void, any> {
    try {
      let { company_id, start_date, end_date } = action.payload;
      if (Array.isArray(company_id) && company_id.length === 1) {
        company_id = company_id[0];
      }
      const [pagamentoConsolidadoPdfResponse] = yield call(pagamentoConsolidadoPdfApi, company_id, start_date, end_date);
      if (pagamentoConsolidadoPdfResponse && pagamentoConsolidadoPdfResponse.length > 0) {
        yield put(fetchPagamentoConsolidadoPdfSuccess());
      } else {
        message.warning('Falha ao exportar lista de pagamentos consolidados em PDF!');
      }
    } catch (error: any) {
      yield put(fetchPagamentoConsolidadoPdfFailure(error.message));
    }
  }

export function* fetchPagamentoConsolidadoXlsSaga(action: any): Generator<any, void, any> {
try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
    company_id = company_id[0];
    }
    const [pagamentoConsolidadoResponse] = yield call(pagamentoConsolidadoXlsApi, company_id, start_date, end_date);
    if (pagamentoConsolidadoResponse && pagamentoConsolidadoResponse.length > 0) {
    yield put(fetchPagamentoConsolidadoXlsSuccess());
    } else {
    message.warning('Falha ao exportar lista de pagamentos consolidados em XLS!');
    }
} catch (error: any) {
    yield put(fetchPagamentoConsolidadoXlsFailure(error.message));
}
}

export function* watchPagamentoConsolidadoSaga() {
  yield takeLatest(FETCH_PAGAMENTOS_CONSOLIDADOS_DATA, fetchPagamentoConsolidadoSaga);
  yield takeLatest(FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA, fetchPagamentoConsolidadoFiltrosSaga);
  yield takeLatest(FETCH_PAGAMENTOS_CONSOLIDADOS_PDF, fetchPagamentoConsolidadoPdfSaga);
  yield takeLatest(FETCH_PAGAMENTOS_CONSOLIDADOS_XLS, fetchPagamentoConsolidadoXlsSaga);
  
}