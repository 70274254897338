import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Col, Row, Skeleton, message } from 'antd';
import { FieldTimeOutlined, LineChartOutlined, DollarCircleOutlined, LikeOutlined, AlertOutlined, FieldNumberOutlined, ReloadOutlined } from '@ant-design/icons';
import { Container } from '../../styles/GlobalStyles';
import TableComponent from '../../components/TablePagination';
import { columnsVenda } from './interfaces/columnsVenda';
import { renderVenda } from './interfaces/vendaDetails';
import { fetchVenda, fetchVendaPdf, fetchVendaXls } from '../../store/states/venda/actions';
import { StatCard } from '../../components/Charts/StatCard';
import CollapseFilter from '../../components/CollapseFilter';
import AnimatedBanner from '../../components/AnimatedBanner';
import { mapFiltersVendaForApi } from '../../utils/validateFilter';
import { DropDownContent } from '../../components/DropDownContent';
import MessageHeader from '../../components/MessageHeader';
import { textVenda } from './interfaces';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';

function VendaScreen() {
  const dispatch = useDispatch();
  const vendaLoadingTotals = useSelector((state: RootState) => state.venda.loadingTotals);
  const vendaLoading = useSelector((state: RootState) => state.venda.loading);
  const vendaLoadingExport = useSelector((state: RootState) => state.venda.loadingVendaExport);
  const vendaTotals = useSelector((state: RootState) => state.venda.vendaTotals);
  const vendaChartData = useSelector((state: RootState) => state.venda.vendaChartData);
  const vendas = useSelector((state: RootState) => state.venda.data);
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerVenda');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [selectedAdquirentes, setSelectedAdquirentes] = useState<string[]>([]);
  const [selectedBanco, setSelectedBanco] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState({});
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vendasTotal, setVendasTotal] = useState(0);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const adquirentesAtivos = new Set(vendaChartData.map((data: any) => data.ADQUIRENTENOME));
  const adquirentesFiltradas = adquirentes ? adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.adquirentenome)) : [];
  const bandeirasAtivas = new Set(vendaChartData.map((data: any) => data.BANDEIRANOME));
  const bandeirasFiltradas = bandeiras ? bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.bandeiranome)) : [];
  const bancosAtivos = new Set(vendaChartData.map((data: any) => data.BANCONOME));
  const bancosFiltrados = bancos ? bancos.filter((banco: any) => bancosAtivos.has(banco.banconome)) : [];
  const estabelecimentos = new Set(vendaChartData.map((data: any) => data.ESTABELECIMENTONUMERO));
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setVendasTotal(vendas.totalList);
    setTotalLiquidoSubFiltro(vendas.totalValorLiquido);
    setTotalBrutoSubFiltro(vendas.totalValorBruto);
    console.log(vendas)
  }, [vendas]);

  useEffect(() => {
    localStorage.setItem('showAcquirerVenda', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  const handleExportPdf = () => {
    message.success('Gerando o relatório de vendas no formato .pdf, aguarde...');
    dispatch(
      fetchVendaPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        filters: mapFiltersVendaForApi(filters),
      }),
    );
  };

  const handleExportXls = () => {
    message.success('Gerando o relatório de vendas no formato .xls, aguarde...');
    dispatch(
      fetchVendaXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        filters: mapFiltersVendaForApi(filters),
      }),
    );
  };

  const handleAdquirenteSelect = (adquirenteName: any) => {
    let newSelectedAdquirentes = [...selectedAdquirentes];
    if (newSelectedAdquirentes.includes(adquirenteName)) {
      newSelectedAdquirentes = newSelectedAdquirentes.filter(name => name !== adquirenteName);
    } else {
      newSelectedAdquirentes.push(adquirenteName);
    }
    setSelectedAdquirentes(newSelectedAdquirentes);
    const newFilters = mapFiltersVendaForApi({ ADQUIRENTENOME: newSelectedAdquirentes });
    setFilters(newFilters);
    fetchVendas(currentPage, pageSize, 'VENDADATA', 'DESC', newFilters);
    setActiveFilter(newSelectedAdquirentes.join(', '));
  };


  const handleBancoSelect = (bancoName: any) => {
    if (selectedBanco === bancoName) {
      setSelectedBanco(null);
      setActiveFilter(null);
      fetchVendas(1, pageSize, 'VENDADATA', 'DESC', mapFiltersVendaForApi({ BANCONOME: null }));
    } else {
      const newFilters = selectedBanco === bancoName ? {} : mapFiltersVendaForApi({ BANCONOME: bancoName });
      setFilters(newFilters);
      fetchVendas(currentPage, pageSize, 'VENDADATA', 'DESC', newFilters);
      setSelectedBanco(bancoName);
      setActiveFilter(bancoName);
      setSelectedAdquirentes([]);
    }
  };

  const fetchVendas = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersVendaForApi(filters);
    dispatch(
      fetchVenda({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'VENDADATA', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchVendas(page, pageSize, sortField, sortOrder, filters);
  };

  const handleRefresh = () => {
    localStorage.removeItem('showAcquirerVenda');
    setFilters({});
    setSelectedAdquirentes([]);
    setSelectedBanco(null);
    setActiveFilter(null);
    setCurrentPage(1);
    setShowAcquirer(true);
    setResetTableFilters(prev => !prev);
    fetchVendas(1, pageSize, 'VENDADATA', 'DESC', {});
    setTableKey(prevKey => prevKey + 1);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textVenda} />
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard
                loadingData={vendaLoadingTotals}
                title="Bruto"
                value={(vendaTotals.vendaTotalBruto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={vendaLoadingTotals}
                title="Líquido"
                value={(vendaTotals.vendaTotalLiquido || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard loadingData={vendaLoadingTotals} title="Média R$" value={(vendaTotals.vendaValorMedio || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',')} icon={<LineChartOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={vendaLoadingTotals} title="Registros" value={parseFloat(vendaTotals.vendaTotalRegistros || 0).toLocaleString('pt-BR')} icon={<FieldNumberOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={vendaLoadingTotals} title="Conciliado(s)" value={vendaTotals.vendasConciliadas || 0} icon={<LikeOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={vendaLoadingTotals} title="Não conciliado(s)" value={vendaTotals.vendasNaoConciliadas || 0} icon={<AlertOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={vendaLoadingTotals} title="Atrasado(s)" value={vendaTotals.vendasAtrasadas || 0} icon={<FieldTimeOutlined />} />
            </Col>
          </Row>
          {vendaLoading ? (
            <Skeleton active />
          ) : (
            <>
              <CollapseFilter
                isCollapseOpen={isCollapseOpen}
                setIsCollapseOpen={setIsCollapseOpen}
                setShowAcquirer={setShowAcquirer}
                showAcquirer={showAcquirer}
                handleAdquirenteSelect={handleAdquirenteSelect}
                handleBancoSelect={handleBancoSelect}
                activeFilter={activeFilter}
                adquirentes={adquirentesFiltradas}
                bancos={bancosFiltrados}
                selectedAdquirentes={selectedAdquirentes}
                selectedBanco={selectedBanco}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10, gap: 5 }}>
                {vendas.list &&
                  <ButtonRefreshList loading={vendaLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                    Limpar Filtros
                  </ButtonRefreshList>}
                {vendas.list && vendas.list.length !== 0 &&
                  <DropDownContent loadingExport={vendaLoadingExport} transacoes={vendas} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
              </div>
            </>
          )}
          <TableComponent
            key={tableKey}
            loadingData={vendaLoading}
            data={vendas.list && vendas.list.length > 0 ? vendas.list : []}
            columns={columnsVenda(false, Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(estabelecimentos), selectedAdquirentes)}
            rowKeyId="VENDAID"
            rowRender={renderVenda}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={vendasTotal}
            onChangePage={handlePageChange}
            expand={true}
            externalFilters={filters}
            totalBrutoSubFiltro={totalBrutoSubFiltro}
            totalLiquidoSubFiltro={totalLiquidoSubFiltro}
            resetFilters={resetTableFilters}
            updateFilters={updateFilters}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default VendaScreen;
