import { ColumnsType } from 'antd/es/table';
import { adquirenteNomeParaImagem, bancoIDParaImagens } from '../../../styles/globalInterfaces';
import { Button, Image, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
export const columnsBankingOfx = (contas: any[], adquirentes: any[], status_conciliacao: any[]): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Data Depósito',
      dataIndex: 'ofxtransacaodatadeposito',
      key: 'ofxtransacaodatadeposito',
      sorter: true,
      render: (text: string) => (text ? moment(text).format('DD/MM/YYYY') : '---'),
    },
    {
      title: 'Conta',
      key: 'ofxarquivoconta',
      sorter: true,
      render: (text: string, record: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image width={25} src={bancoIDParaImagens[record.ofxarquivobanco]} alt={record.ofxarquivobanco} preview={false} style={{ marginRight: 8 }} />
          <span style={{ marginLeft: 8 }}>{record.ofxarquivoconta}</span>
        </div>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione a(s) conta(s)" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {contas.map((conta: any) => (
              <Option key={conta} value={conta}>
                {conta}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.ofxarquivoconta);
      },
    },
    {
      title: 'Descrição Depósito',
      dataIndex: 'ofxtransacaodescricaodeposito',
      key: 'ofxtransacaodescricaodeposito',
      sorter: true,
      render: (text: any) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Pesquisar pela descrição"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 505, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      filterIcon: (filtered) => <span style={{ color: filtered ? '#1890ff' : undefined }}>🔍</span>,
      onFilter: (value, record) => (record.ofxtransacaodescricaodeposito as string).includes(record.ofxtransacaodescricaodeposito),
    },
    {
      title: 'Valor',
      dataIndex: 'ofxtransacaovalordeposito',
      key: 'ofxtransacaovalordeposito',
      sorter: true,
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180}}>
          <Input
            placeholder="Pesquisar pelo valor"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      filterIcon: (filtered) => <span style={{ color: filtered ? '#1890ff' : undefined }}>🔍</span>,
      onFilter: (value, record) =>
        record.ofxtransacaovalordeposito !== null &&
        record.ofxtransacaovalordeposito
          .toString()
          .includes(record.ofxtransacaovalordeposito.toString()),
    },
    {
      title: 'Adquirente',
      dataIndex: 'adquirentenome',
      key: 'adquirentenome',
      sorter: true,
      render: (text: string, record: any) => <Image preview={false} width={35} src={adquirenteNomeParaImagem[record.adquirentenome.toLowerCase().replace(/\s/g, '')]} alt={record.adquirentenome} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione a(s) adquirente(s)" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {adquirentes.map((adquirente: any) => (
              <Option key={adquirente} value={adquirente}>
                {adquirente}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.adquirentenome);
      },
    },
    {
      title: 'Status Conciliação',
      dataIndex: 'ofxtransacaostatusconciliacao',
      key: 'ofxtransacaostatusconciliacao',
      sorter: true,
      render: (text: any) => (text ? 'Conciliado' : 'Não Conciliado'),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione a(s) conta(s)" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {status_conciliacao.map((status: any) => (
              <Option key={status} value={status}>
                {status === true ? 'Conciliado' : 'Não Conciliado'}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.ofxtransacaostatusconciliacao);
      },
    },
  ];
  return columns;
};
