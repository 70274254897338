import moment from 'moment';
import api from '../../services/axios';
import { message } from 'antd';
import { saveAs } from 'file-saver';

export interface LoginResponse {
  access_token: string;
}

export async function loginApi(username: string, password: string, customer: string): Promise<LoginResponse> {
  const response = await api.post<LoginResponse>('/login', {
    username,
    password,
    customer,
  });
  return response.data;
}

export async function getUserRuleApi(): Promise<any> {
  const response = await api.get('/usuario-regras', {});
  return response.data;
}

export async function vendaAuditAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/venda/vendaAuditAnalytics';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function vendaAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/venda/vendaChartAnalytics';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function vendaChartDataApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/venda/vendaChartData';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function vendaTotalsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/venda/vendaTotals';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function vendaApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
  const baseURL = '/venda/all';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField: sortField.toString(),
    sortOrder: sortOrder.toString(),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function vendaPdfApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
  const baseURL = '/venda/export/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_venda.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function vendaXlsApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
  const baseURL = '/venda/export/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_venda.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function vendaAuditadaApiXls(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/venda/export-audit/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_venda_auditada.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo auditado em XLSX: ${error}`);
  }
}

export async function vendaAuditadaApiPdf(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/venda/export-audit/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_vendas_auditadas.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function pagamentoChartDataApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/pagamento/pagamentoChartData';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function pagamentoAuditAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/pagamento/pagamentoAuditAnalytics';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function pagamentoAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/pagamento/pagamentoChartAnalytics';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function pagamentoTotalsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/pagamento/pagamentoTotals';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function agendaRecebimentosTotalsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/agenda-recebimentos/recebimentosTotals';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function agendaRecebimentosApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
  const baseURL = '/agenda-recebimentos/all';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField: sortField.toString(),
    sortOrder: sortOrder.toString(),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function agendaRecebimentosChartDataApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
  const baseURL = '/agenda-recebimentos/recebimentosChartData';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function agendaRecebimentosXlsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/agenda-recebimentos/export/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_recebiveis.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo XLS: ${error}`);
  }
}

export async function agendaRecebimentosPdfApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/agenda-recebimentos/export/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_recebiveis.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function pagamentoApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
  const baseURL = '/pagamento/all';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField: sortField.toString(),
    sortOrder: sortOrder.toString(),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function pagamentoPdfApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
  const baseURL = '/pagamento/export/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_pagamento.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function pagamentoXlsApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
  const baseURL = '/pagamento/export/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_pagamento.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo XLSX: ${error}`);
  }
}

export async function pagamentoAuditadoApiXls(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
  const baseURL = '/pagamento/export-audit/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_pagamentos_auditados.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo auditado em XLSX: ${error}`);
  }
}

export async function pagamentoAuditadoApiPdf(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
  const baseURL = '/pagamento/export-audit/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_pagamentos_auditados.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function pagamentoConsolidadoApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
  const baseURL = '/pagamento-consolidado/all';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField: sortField.toString(),
    sortOrder: sortOrder.toString(),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function pagamentoConsolidadoFiltroApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 100000, sortField: any, sortOrder: any): Promise<any> {
  const baseURL = '/pagamento-consolidado/all';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField: sortField.toString(),
    sortOrder: sortOrder.toString(),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function pagamentoConsolidadoPdfApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/pagamento-consolidado/export/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_pagamento_consolidado.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}

export async function pagamentoConsolidadoXlsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/pagamento-consolidado/export/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_pagamento_consolidado.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo XLS: ${error}`);
  }
}

export async function bankingOfxApi(): Promise<any> {
  const response = await api.get('/banking-ofx/all', {});
  return response.data;
}

export async function empresaApi(user_id: number): Promise<any> {
  const response = await api.get('/empresa', {
    params: { user_id },
  });
  return response.data;
}

export async function estabelecimentoApi(empresa_id: number): Promise<any> {
  const response = await api.get('/estabelecimentos', {
    params: { empresa_id },
  });
  return response.data;
}

export async function recoverPasswordApi(subdomain: string, email: string): Promise<any> {
  const response = await api.post('/recover_password', {
    subdomain,
    email,
  });
  message.info(response.data.message);
  return response.data;
}

export async function resetPasswordApi(subdomain: string, token: string, password: string): Promise<any> {
  const response = await api.post(`/reset_password/${token}`, {
    subdomain: subdomain,
    new_password: password,
  });
  message.success(response.data.message);
}

export async function proposalApi(proposal: any): Promise<any> {
  const response = await api.post('/proposta', proposal);
  message.success(response.data.message);
}

export async function uploadPhotoApi(formData: FormData): Promise<any> {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await api.post('/upload-photo', formData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function userProfileApi(): Promise<any> {
  const response = await api.get('/profile', {});
  return response.data;
}

export async function adquirenteApi(): Promise<any> {
  const response = await api.get('/adquirente/all', {});
  return response.data;
}

export async function bancoApi(): Promise<any> {
  const response = await api.get('/banco/all', {});
  return response.data;
}

export async function bandeiraApi(): Promise<any> {
  const response = await api.get('/bandeira/all', {});
  return response.data;
}

export async function modalidadeApi(): Promise<any> {
  const response = await api.get('/modalidade/all', {});
  return response.data;
}

export async function gestaoTaxaApi(): Promise<any> {
  const response = await api.get('/gestao-taxas/all', {});
  return response.data;
}

export async function changeRateApi(rateId: number, updatedData: any): Promise<any> {
  const response = await api.put(`/gestao-taxas/edit/${rateId}`, updatedData);
  return response.data;
}

export async function createRateApi(newRate: any): Promise<any> {
  const response = await api.post(`/gestao-taxas/create`, newRate);
  message.info(response.data.message);
  return response.data;
}

export async function deleteRateApi(id: number): Promise<any> {
  const response = await api.delete(`/gestao-taxas/delete/${id}`);
  return response.data;
}

export async function notificationApi(empresa_id: number): Promise<any> {
  const response = await api.get('/notificacoes/all', {
    params: { empresa_id },
  });
  return response.data;
}

export async function updateNotificationApi(notification_id: number, status: number): Promise<any> {
  const response = await api.post(`/notificacoes/update/${notification_id}/${status}`);
  return response.data;
}

export async function uploadOfxAPi(file: File): Promise<any> {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await api.post('/upload-ofx', formData, {
      baseURL: process.env.REACT_APP_OFX_API,
      validateStatus: (status) => {
        return status >= 200 && status < 500;
      }});
    console.log(response.status)
    if (response.status !== 200) {
      return response;
    }
    return response;
  } catch (error) {
    console.error('Erro ao fazer o upload:', error);
    throw error;
  }
}


export async function bankingOfxTransactionsApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
  const baseURL = '/banking-ofx/transacoes-all';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField: sortField.toString(),
    sortOrder: sortOrder.toString(),
  });
  if (Array.isArray(company_id)) {
    for (const id of company_id) {
      params.append('company_id', id.toString());
    }
  } else {
    params.append('company_id', company_id.toString());
  }
  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  });
  const url = `${baseURL}?${params.toString()}`;
  const response = await api.get(url);
  return response.data;
}

export async function BankingOfxXlsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/banking-ofx/transacoes/export/xls';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
    });
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, 'relatorio_recebiveis.xlsx');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo XLS: ${error}`);
  }
}

export async function BankingOfxPdfApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
  const baseURL = '/banking-ofx/transacoes/export/pdf';
  const params = new URLSearchParams({
    start_date: moment(start_date).format('YYYY-MM-DD'),
    end_date: moment(end_date).format('YYYY-MM-DD'),
  });
  const url = `${baseURL}?${params.toString()}`;
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(blob, 'relatorio_recebiveis.pdf');
  } catch (error) {
    message.error(`Erro ao baixar o arquivo PDF: ${error}`);
  }
}
