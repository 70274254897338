import { Button } from 'antd';
import { styled } from 'styled-components';

export const ButtonEnviarOfxStyled = styled(Button)`
  background: linear-gradient(135deg, #536390, #252f64) !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  &:hover,
  &:focus {
    background: linear-gradient(135deg, #252f64, #536390) !important;
    color: #f0f0f0 !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important;
  }
  .anticon {
    margin-right: 8px !important;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  text-align: left;
  padding: 16px;
  margin: 16px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* Arredondar os cantos */
`;

export const UploadStatusContainer = styled.div`
  text-align: center;
  padding: 16px;
  margin: 16px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* Arredondar os cantos */
`;

export const ButtonAlterarOfxStyled = styled(Button)`
  background: linear-gradient(135deg, #536390, #252f64) !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  &:hover,
  &:focus {
    background: linear-gradient(135deg, #252f64, #536390) !important;
    color: #f0f0f0 !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important;
  }
`;