import { takeLatest, call, put, all } from 'redux-saga/effects';
import { pagamentoAnalyticsApi, pagamentoApi, pagamentoAuditadoApiPdf, pagamentoAuditadoApiXls, pagamentoAuditAnalyticsApi, pagamentoChartDataApi, pagamentoPdfApi, pagamentoTotalsApi, pagamentoXlsApi } from '../../target-api/api';
import {
  fetchPagamentoAuditAnalyticsSuccess,
  fetchPagamentoAuditAnalyticsFailure,
  fetchPagamentoSuccess,
  fetchPagamentoFailure,
  fetchPagamentoPdfSuccess,
  fetchPagamentoTotalsSuccess,
  fetchPagamentoTotalsFailure,
  fetchPagamentoChartDataSuccess,
  fetchPagamentoXlsSuccess,
  fetchPagamentoXlsFailure,
  fetchPagamentoPdfFailure,
  fetchPagamentoAnalyticsSuccess,
  fetchPagamentoAnalyticsFailture,
  fetchPagamentoAuditadoPdfSuccess,
  fetchPagamentoAuditadoPdfFailure,
  fetchPagamentoAuditadoXlsSuccess,
  fetchPagamentoAuditadoXlsFailure,
} from './actions';
import { FETCH_PAGAMENTO_ANALYTICS, FETCH_PAGAMENTO_AUDIT_ANALYTICS, FETCH_PAGAMENTO_AUDITADO_PDF, FETCH_PAGAMENTO_AUDITADO_XLS, FETCH_PAGAMENTO_DATA, FETCH_PAGAMENTO_PDF, FETCH_PAGAMENTO_TOTALS, FETCH_PAGAMENTO_XLS } from './types';
import { message } from 'antd';

export function* fetchPagamentoAuditAnalytics(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [totalsAuditAnalyticsResponse] = yield all([call(pagamentoAuditAnalyticsApi, company_id, start_date, end_date)]);
    if (totalsAuditAnalyticsResponse && totalsAuditAnalyticsResponse.length <= 0) {
      message.warning('Nenhum total de analytics de pagamento auditado encontrado!');
    } else {
      yield put(fetchPagamentoAuditAnalyticsSuccess(totalsAuditAnalyticsResponse));
    }
  } catch (error: any) {
    yield put(fetchPagamentoAuditAnalyticsFailure(error.message));
  }
}

export function* fetchPagamentoAnalytics(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [vendaAnalyticsResponse] = yield all([call(pagamentoAnalyticsApi, company_id, start_date, end_date)]);
    if (vendaAnalyticsResponse && vendaAnalyticsResponse.length <= 0) {
      message.warning('Nenhum dado analytics de pagamento encontrado!');
      yield put(fetchPagamentoAnalyticsSuccess({ company_id: [], start_date: '', end_date: '' }));
    } else {
      yield put(fetchPagamentoAnalyticsSuccess(vendaAnalyticsResponse));
    }
  } catch (error: any) {
    yield put(fetchPagamentoAnalyticsFailture(error.message));
  }
}

export function* fetchPagamentoTotalsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [totalsPagamentoResponse, pagamentoChartDataResponse] = yield all([call(pagamentoTotalsApi, company_id, start_date, end_date), call(pagamentoChartDataApi, company_id, start_date, end_date)]);
    if (totalsPagamentoResponse && totalsPagamentoResponse.length <= 0) {
      message.warning('Nenhum total de pagamento encontrado!');
      yield put(fetchPagamentoTotalsSuccess({}));
    } else {
      yield put(fetchPagamentoTotalsSuccess(totalsPagamentoResponse));
    }
    if (pagamentoChartDataResponse && pagamentoChartDataResponse.length <= 0) {
      message.warning('Nenhum dado do dashboard de pagamento encontrado!');
      yield put(fetchPagamentoChartDataSuccess([]));
    } else {
      yield put(fetchPagamentoChartDataSuccess(pagamentoChartDataResponse));
    }
  } catch (error: any) {
    yield put(fetchPagamentoTotalsFailure(error.message));
  }
}

export function* fetchPagamentoSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [pagamentoResponse] = yield all([call(pagamentoApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder)]);
    if (pagamentoResponse && pagamentoResponse.length <= 0) {
      message.warning('Nenhum pagamento encontrado!');
      yield put(fetchPagamentoSuccess([]));
    } else {
      yield put(fetchPagamentoSuccess(pagamentoResponse));
    }
  } catch (error: any) {
    yield put(fetchPagamentoFailure(error.message));
    yield put(fetchPagamentoTotalsFailure(error.message));
  }
}

export function* fetchPagamentoPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, filters } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [pagamentoPdfResponse] = yield call(pagamentoPdfApi, company_id, start_date, end_date, filters);
    if (pagamentoPdfResponse && pagamentoPdfResponse.length > 0) {
      yield put(fetchPagamentoPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de vendas em PDF!');
    }
  } catch (error: any) {
    yield put(fetchPagamentoPdfFailure(error.message));
  }
}

export function* fetchPagamentoXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, filters } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [pagamentoXlsResponse] = yield call(pagamentoXlsApi, company_id, start_date, end_date, filters);
    if (pagamentoXlsResponse && pagamentoXlsResponse.length > 0) {
      yield put(fetchPagamentoXlsSuccess());
    } else {
      message.warning('Falha ao exportar lista de vendas em XLSX!');
    }
  } catch (error: any) {
    yield put(fetchPagamentoXlsFailure(error.message));
  }
}

export function* fetchPagamentoAuditadoPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, filters } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [response] = yield call(pagamentoAuditadoApiPdf, company_id, start_date, end_date, filters);
    if (response && response.length > 0) {
      yield put(fetchPagamentoAuditadoPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de pagamentos auditados em PDF!');
    }
  } catch (error: any) {
    yield put(fetchPagamentoAuditadoPdfFailure(error.message));
  }
}

export function* fetchPagamentoAuditadoXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, filters } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [response] = yield call(pagamentoAuditadoApiXls, company_id, start_date, end_date, filters);
    if (response && response.length > 0) {
      yield put(fetchPagamentoAuditadoXlsSuccess(response));
    } else {
      message.warning('Falha ao exportar lista de pagamentos auditados em XLS!');
    }
  } catch (error: any) {
    yield put(fetchPagamentoAuditadoXlsFailure(error.message));
  }
}

export function* watchPagamentoSaga() {
  yield takeLatest(FETCH_PAGAMENTO_AUDIT_ANALYTICS, fetchPagamentoAuditAnalytics);
  yield takeLatest(FETCH_PAGAMENTO_ANALYTICS, fetchPagamentoAnalytics);
  yield takeLatest(FETCH_PAGAMENTO_TOTALS, fetchPagamentoTotalsSaga);
  yield takeLatest(FETCH_PAGAMENTO_DATA, fetchPagamentoSaga);
  yield takeLatest(FETCH_PAGAMENTO_PDF, fetchPagamentoPdfSaga);
  yield takeLatest(FETCH_PAGAMENTO_XLS, fetchPagamentoXlsSaga);
  yield takeLatest(FETCH_PAGAMENTO_AUDITADO_PDF, fetchPagamentoAuditadoPdfSaga);
  yield takeLatest(FETCH_PAGAMENTO_AUDITADO_XLS, fetchPagamentoAuditadoXlsSaga);
}
