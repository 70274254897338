import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Col, Row, message, Skeleton } from 'antd';
import { Container } from '../../styles/GlobalStyles';
import { SafetyCertificateOutlined, QuestionCircleOutlined, BugOutlined, CaretUpOutlined, CaretDownOutlined, FireOutlined, FieldNumberOutlined, ReloadOutlined } from '@ant-design/icons';
import LegendChartVenda from '../../components/Charts/LegendChartVenda';
import TableComponent from '../../components/TablePagination';
import { columnsVenda } from '../VendaScreen/interfaces/columnsVenda';
import { renderVenda } from '../VendaScreen/interfaces/vendaDetails';
import { StatCard } from '../../components/Charts/StatCard';
import { fetchVenda, fetchVendaAuditadaPdf, fetchVendaAuditadaXls } from '../../store/states/venda/actions';
import AnimatedBanner from '../../components/AnimatedBanner';
import { mapFiltersVendaForApi } from '../../utils/validateFilter';
import { DropDownContent } from '../../components/DropDownContent';
import { textHeaderAnalytics } from './interfaces/all';
import MessageHeader from '../../components/MessageHeader';
import { formatCurrency } from '../../utils/validateNumber';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';

interface Filters {
  TAGTYPE?: string[];
}

function AnaliseVendaScreen() {
  const dispatch = useDispatch();
  const loadingAnalytics = useSelector((state: RootState) => state.venda.loadingAnalytics);
  const vendaAnalytics = useSelector((state: RootState) => state.venda.vendaAnalytics);
  const vendaAuditAnalytics = useSelector((state: RootState) => state.venda.vendaAuditAnalytics);
  const vendaLoading = useSelector((state: RootState) => state.venda.loading);
  const loadingExportAudit = useSelector((state: RootState) => state.venda.loadingVendaExportAudit);
  const vendas = useSelector((state: RootState) => state.venda.data);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [vendasTotal, setVendasTotal] = useState(0);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});
  const vendaChartData = useSelector((state: RootState) => state.venda.vendaChartData);
  const adquirentesAtivos = new Set(vendaChartData.map((data: any) => data.ADQUIRENTENOME));
  const adquirentesFiltradas = adquirentes ? adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.adquirentenome)) : [];
  const bandeirasAtivas = new Set(vendaChartData.map((data: any) => data.BANDEIRANOME));
  const bandeirasFiltradas = bandeiras ? bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.bandeiranome)) : [];
  const estabelecimentos = new Set(vendaChartData.map((data: any) => data.ESTABELECIMENTONUMERO));
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setVendasTotal(vendas.totalList);
    if (currentFilters && currentFilters.TAGTYPE) {
      switch (currentFilters.TAGTYPE[0]) {
        case 'orange':
          setVendasTotal(vendaAuditAnalytics.CADASTRAR);
          break;
        case 'red':
          setVendasTotal(vendaAuditAnalytics.DIVERGENCIA);
          break;
        case 'green':
          setVendasTotal(vendaAuditAnalytics.VALIDA);
          break;
        default:
          setVendasTotal(vendas.totalList);
          break;
      }
    }
    setTotalLiquidoSubFiltro(vendas.totalValorLiquido);
    setTotalBrutoSubFiltro(vendas.totalValorBruto);
  }, [vendas, currentFilters]);

  const handleExportPdf = () => {
    message.success('Gerando o relatório de vendas auditadas no formato .pdf, aguarde...');
    dispatch(
      fetchVendaAuditadaPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleExportXls = () => {
    message.success('Gerando o relatório de vendas auditadas no formato .xlsx, aguarde...');
    dispatch(
      fetchVendaAuditadaXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const fetchVendas = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const sort = sortField !== undefined ? sortField : 'VENDADATA';
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersVendaForApi(filters);
    if (filters && filters.TAGTYPE) {
      setCurrentFilters({ TAGTYPE: filters.TAGTYPE });
    }
    dispatch(
      fetchVenda({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField: sort,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'VENDADATA', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    fetchVendas(page, pageSize, sortField, sortOrder, filters);
  };

  const signalPotencial = () => {
    const ganho = Number(Math.abs(vendaAuditAnalytics.TOTALGANHO));
    const perda = Number(Math.abs(vendaAuditAnalytics.TOTALPERDA));
    if (ganho > perda) {
      return '+';
    } else if (ganho < perda) {
      return '-';
    } else {
      return '';
    }
  };

  const handleRefresh = () => {
    setFilters({});
    setCurrentPage(1);
    setResetTableFilters(prev => !prev);
    fetchVendas(1, 10, 'VENDADATA', 'DESC', {});
    setTableKey(prevKey => prevKey + 1);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textHeaderAnalytics} />
          <Row gutter={16}>
            <Col span={24}>
              <Row gutter={16}>
                <Col flex="5">
                  <StatCard loadingData={loadingAnalytics} title="Registros" value={vendaAuditAnalytics.TOTAL || 0} icon={<FieldNumberOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingAnalytics} title="Válidas" value={vendaAuditAnalytics.VALIDA || 0} icon={<SafetyCertificateOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingAnalytics} title="Divergências" value={vendaAuditAnalytics.DIVERGENCIA || 0} icon={<BugOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingAnalytics} title="Sem cadastro" value={vendaAuditAnalytics.CADASTRAR || 0} icon={<QuestionCircleOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingAnalytics} title="Ganho" value={formatCurrency(vendaAuditAnalytics.TOTALGANHO || 0)} icon={<CaretUpOutlined style={{ color: 'green' }} />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingAnalytics} title="Perda" value={formatCurrency(vendaAuditAnalytics.TOTALPERDA || 0)} icon={<CaretDownOutlined style={{ color: 'red' }} />} />
                </Col>
                <Col flex="5">
                  <StatCard
                    loadingData={loadingAnalytics}
                    title="Potencial"
                    value={signalPotencial() + formatCurrency(Math.abs(vendaAuditAnalytics.TOTALGANHO) - vendaAuditAnalytics.TOTALPERDA || 0)}
                    icon={<FireOutlined style={{ color: 'orange' }} />}
                  />
                </Col>
              </Row>
              {vendaLoading ? (
                <Skeleton active />
              ) : (
                <>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <LegendChartVenda vendaAnalytics={vendaAnalytics && vendaAnalytics.length > 0 ? vendaAnalytics : []} />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10, gap: 5 }}>
                    {vendas.list &&
                      <ButtonRefreshList loading={vendaLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                        Limpar Filtros
                      </ButtonRefreshList>}
                    {vendas.list && vendas.list.length !== 0 &&
                      <DropDownContent loadingExport={loadingExportAudit} transacoes={vendas} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
                  </div>
                </>
              )}
              <TableComponent
                key={tableKey}
                loadingData={vendaLoading}
                data={vendas.list && vendas.list.length > 0 ? vendas.list : []}
                columns={columnsVenda(true, Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(estabelecimentos))}
                rowKeyId="VENDAID"
                rowRender={renderVenda}
                perPage={perPage}
                currentPage={currentPage}
                totalPages={vendasTotal}
                onChangePage={handlePageChange}
                expand={true}
                externalFilters={[]}
                totalBrutoSubFiltro={totalBrutoSubFiltro}
                totalLiquidoSubFiltro={totalLiquidoSubFiltro}
                resetFilters={resetTableFilters}
                updateFilters={updateFilters}
              />
            </Col>
          </Row>
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default AnaliseVendaScreen;
