import { useMemo, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { adquirenteColors, bancoColors, bandeiraColors, empresaColors, estabelecimentoColors } from '../../../styles/globalInterfaces';
import { Card, Empty, Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import backgroundImage from '../../../assets/global/background.svg';
import { AnimatedSector, ChartContainer, ColorIndicator, LegendContainer, LegendItem, LegendText, PieChartContainer, ResponsiveSpan } from './styled';

const { Option } = Select;
function PieChartDash({ titleBoard, transactions }: any) {
  const [viewMode, setViewMode] = useState('ADQUIRENTE');
  const vendaLoading = useSelector((state: RootState) => state.venda.loading);
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);
  const [activeIndex, setActiveIndex] = useState(-1);

  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
  };

  const handleLegendClick = (index: any) => {
    setActiveIndex(index);
  };

  const groupData = (data: any[], key: string) => {
    return data.reduce((acc, cur) => {
      const name = cur[key];
      if (!acc[name]) {
        acc[name] = { totalTransacoes: 0, totalBruto: 0, totalLiquido: 0 };
      }
      acc[name].totalBruto += Number(cur.TOTALVALORBRUTO || cur.TOTALVALORBRUTO || 0);
      acc[name].totalLiquido += Number(cur.TOTALVALORLIQUIDO || cur.TOTALVALORLIQUIDO || 0);
      acc[name].totalTransacoes += cur.TOTALTRANSACOES;
      return acc;
    }, {});
  };
  const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    const fontSize = Math.max(6, outerRadius / 12);
    
    const total = transactionsData.reduce((acc, entry) => acc + entry.totalBruto, 0);
    const percentage = ((payload.totalBruto / total) * 100).toFixed(2); 
  
    return (
      <g>
        <text x={cx} y={cy - 30} textAnchor="middle" fill={fill} style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: `${fontSize}px` }}>
          {payload.name}
        </text>
        <AnimatedSector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius + 10} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        <text x={cx} y={cy - 0} textAnchor="middle" fill="#ffffff" style={{ fontFamily: 'Poppins', fontSize: `${fontSize}px` }}>
          {' '}
          {`Bruto: ${payload.totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        </text>
        <text x={cx} y={cy + 20} textAnchor="middle" fill="#ffffff" style={{ fontFamily: 'Poppins', fontSize: `${fontSize}px` }}>
          {`Líquido: ${payload.totalLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        </text>
        <text x={cx} y={cy + 40} textAnchor="middle" fill="#ffffff" style={{ fontFamily: 'Poppins', fontSize: `${fontSize}px` }}>
          {`Transações: ${payload.totalTransacoes}`}
        </text>
        <text x={cx} y={cy + 60} textAnchor="middle" fill="#ffffff" style={{ fontFamily: 'Poppins', fontSize: `${fontSize}px` }}>
          {`Porcentagem: ${percentage}%`} {/* Porcentagem calculada */}
        </text>
      </g>
    );
  };

  const transactionsData = useMemo(() => {
    let key = 'ADQUIRENTENOME';
    if (viewMode === 'ADQUIRENTE') {
      key = 'ADQUIRENTENOME';
    } else if (viewMode === 'BANDEIRA') {
      key = 'BANDEIRANOME';
    } else if (viewMode === 'BANCO') {
      key = 'BANCONOME';
    } else if (viewMode === 'EMPRESA') {
      key = 'EMPRESANOME';
    } else if (viewMode === 'ESTABELECIMENTO') {
      key = 'ESTABELECIMENTONUMERO';
    }
    const grouped = groupData(transactions, key);
    return Object.keys(grouped).map((name) => ({
      name,
      ...grouped[name],
    }));
  }, [transactions, viewMode]);

  return (
    <>
      {!vendaLoading && !pagamentoLoading && (
        <>
          <>
            <Card
              title={<div style={{ color: '#fff' }}>{titleBoard}</div>}
              bordered={false}
              style={{ background: `url(${backgroundImage})`, backgroundColor: '#252f64', backgroundBlendMode: 'multiply', backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
              <Select defaultValue="ADQUIRENTE" style={{ width: 200, marginBottom: 20 }} onChange={setViewMode}>
                <Option value="ADQUIRENTE">Adquirentes</Option>
                <Option value="BANDEIRA">Bandeiras</Option>
                <Option value="BANCO">Bancos</Option>
                <Option value="EMPRESA">Empresas</Option>
                <Option value="ESTABELECIMENTO">Estabelecimentos</Option>
              </Select>
              {transactionsData.length > 0 ? (
                <ChartContainer>
                  <PieChartContainer>
                    <PieChart style={{ cursor: 'pointer' }} width={Math.min(800, window.innerWidth - 100)} height={Math.min(400, window.innerWidth / 2)}>
                      <Pie
                        style={{ cursor: 'pointer' }}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={transactionsData}
                        cx="50%"
                        cy="50%"
                        innerRadius={Math.min(100, window.innerWidth / 10)}
                        outerRadius={Math.min(170, window.innerWidth / 8)}
                        fill="#8884d8"
                        dataKey="totalBruto"
                        onMouseEnter={onPieEnter}
                        onMouseLeave={onPieLeave}
                        minAngle={18}
                        labelLine={false}
                        label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                          const total = transactionsData.reduce((acc, entry) => acc + entry.totalBruto, 0);
                          const percentage = ((value / total) * 100).toFixed(2);
                          const RADIAN = Math.PI / 180;
                          const radius = innerRadius + (outerRadius - innerRadius) / 2;
                          const x = cx + radius * Math.cos(-midAngle * RADIAN);
                          const y = cy + radius * Math.sin(-midAngle * RADIAN);
                          return (
                            <text x={x} y={y} textAnchor="middle" fill="#252f64" style={{ fontSize: 13, fontFamily: 'Poppins'}}>
                              {`${percentage}%`}
                            </text>
                          );
                        }}
                      >
                        {transactionsData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              viewMode === 'EMPRESA'
                                ? empresaColors(index + 1)
                                : viewMode === 'ADQUIRENTE'
                                ? adquirenteColors[entry.name as keyof typeof adquirenteColors]
                                : viewMode === 'BANDEIRA'
                                ? bandeiraColors[entry.name as keyof typeof bandeiraColors]
                                : viewMode === 'BANCO'
                                ? bancoColors(index + 1)
                                : viewMode === 'ESTABELECIMENTO'
                                ? estabelecimentoColors(index + 10)
                                : '#4d9b7d'
                            }
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </PieChartContainer>
                  <LegendContainer>
                    {transactionsData.map((entry, index) => (
                      <LegendItem
                        key={`legend-item-${index}`}
                        onClick={() => handleLegendClick(index)}
                        color={
                          viewMode === 'EMPRESA'
                            ? empresaColors(index + 1)
                            : viewMode === 'ADQUIRENTE'
                            ? adquirenteColors[entry.name as keyof typeof adquirenteColors]
                            : viewMode === 'BANDEIRA'
                            ? bandeiraColors[entry.name as keyof typeof bandeiraColors]
                            : viewMode === 'BANCO'
                            ? bancoColors(index + 1)
                            : viewMode === 'ESTABELECIMENTO'
                            ? estabelecimentoColors(index + 10)
                            : '#4d9b7d'
                        }
                      >
                        <ColorIndicator
                          color={
                            viewMode === 'EMPRESA'
                              ? empresaColors(index + 1)
                              : viewMode === 'ADQUIRENTE'
                              ? adquirenteColors[entry.name as keyof typeof adquirenteColors]
                              : viewMode === 'BANDEIRA'
                              ? bandeiraColors[entry.name as keyof typeof bandeiraColors]
                              : viewMode === 'BANCO'
                              ? bancoColors(index + 1)
                              : viewMode === 'ESTABELECIMENTO'
                              ? estabelecimentoColors(index + 10)
                              : '#4d9b7d'
                          }
                        />
                        <LegendText>
                          <ResponsiveSpan>
                            {entry.name}: {entry.totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          </ResponsiveSpan>
                        </LegendText>
                      </LegendItem>
                    ))}
                  </LegendContainer>
                </ChartContainer>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span style={{ color: 'white' }}>Não há dados disponíveis</span>} />
              )}
            </Card>
          </>
        </>
      )}
    </>
  );
}

export default PieChartDash;
