import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

export interface UserType {
  name: string;
  user_group_id: number;
}

export interface BreadCrumbProps {
  user: UserType;
}

const CustomBreadCrumb = styled(Breadcrumb)`
  font-family: 'Poppins';
  font-size: 14px;
  width: 250px;
  padding: 14px;
  background: '#fff';

  @media (max-width: 1350px) {
    font-size: 12px;
  }

  @media (max-width: 500px) {
    padding: 16px;
    font-size: 10px;
  }
`;

const ContentBreadcrumb: React.FC<BreadCrumbProps> = () => {
  const location = useLocation();

  let breadcrumbName;
  switch (location.pathname) {
    case '/dashboard':
      breadcrumbName = 'Dashboard';
      break;
    case '/venda':
      breadcrumbName = 'Vendas';
      break;
    case '/pagamento':
      breadcrumbName = 'Pagamentos';
      break;
    case '/banking-ofx':
      breadcrumbName = 'Bancária OFX';
      break;
    case '/agenda-recebimentos':
      breadcrumbName = 'Recebíveis';
      break;
    case '/analise-vendas':
      breadcrumbName = 'Analytics (V)';
      break;
    case '/analise-pagamentos':
      breadcrumbName = 'Analytics (P)';
      break;
    case '/gestao-taxa':
      breadcrumbName = 'Taxas';
      break;
    case '/pagamento-consolidado':
      breadcrumbName = 'Consolidados';
      break;
    
    default:
      breadcrumbName = 'Home';
  }

  return (
    <CustomBreadCrumb>
      <Breadcrumb.Item>
        <Link to="/dashboard">Card</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <strong style={{ color: '#252f64' }}>{breadcrumbName}</strong>
      </Breadcrumb.Item>
    </CustomBreadCrumb>
  );
};

export default ContentBreadcrumb;
