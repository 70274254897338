import {
  FETCH_NOTIFICATION_DATA,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
} from './types';

export const fetchNotification = () => ({
  type: FETCH_NOTIFICATION_DATA,
});

export const fetchNotificationSuccess = (data: any[]) => ({
  type: FETCH_NOTIFICATION_SUCCESS,
  data,
});

export const fetchNotificationFailure = (error: any) => ({
  type: FETCH_NOTIFICATION_FAILURE,
  payload: error,
});

export const updateNotification = (payload: { notification_id: number; status: number; }) => ({
  type: UPDATE_NOTIFICATION,
  payload,
});

export const updateNotificationSuccess = (data: any) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  data,
});

export const updateNotificationFailure = (error: any) => ({
  type: UPDATE_NOTIFICATION_FAILURE,
  payload: error,
});
