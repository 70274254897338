import { CalendarOutlined, CaretUpOutlined, ClockCircleOutlined, CrownOutlined, FieldTimeOutlined, HomeOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import moment from 'moment';

export const renderIntegracaoVenda = (record: any) => {
  return (
    <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
      <div style={{ marginBottom: 15 }}>
        <CaretUpOutlined style={{ marginRight: 10 }} />
        <strong>Transação de Venda</strong>
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <div>
            <ClockCircleOutlined style={{ marginRight: 10 }} />
            <span>Data: </span>
            <strong>{moment(record.VENDADATA).format('DD/MM/YYYY')}</strong>
          </div>
          <div>
            <FieldTimeOutlined style={{ marginRight: 10 }} />
            <span>Hora: </span>
            <strong>{record.VENDAHORA}</strong>
          </div>
          <div>
            <CalendarOutlined style={{ marginRight: 10 }} />
            <span>Previsão Pagamento: </span>
            <strong>{record.VENDADATAPREVISAO ? moment(record.VENDADATAPREVISAO).format('DD/MM/YYYY') : 'N/A'}</strong>
          </div>
          <div style={{ marginTop: 10 }}>
            <CrownOutlined style={{ marginRight: 10 }} />
            <span>Empresa: </span>
            <strong>{record.empresanome}</strong>
          </div>
          <div>
            <HomeOutlined style={{ marginRight: 10 }} />
            <span>Estabelecimento: </span>
            <strong>{record.estabelecimentonumero}</strong>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
