import React from 'react';
import { Container, Header, Section, Footer } from './styled';

const PoliticaPrivacidadeScreen: React.FC = () => {
    return (
        <Container>
            <Header>Política de Privacidade do Finly Card</Header>
            <Section>
                <h2>1. Introdução</h2>
                <p>
                    Esta Política de Privacidade descreve como o Finly Card ("Nós") coleta, utiliza, processa e compartilha informações pessoais dos usuários ("Você").
                    Esta política aplica-se a todas as interações com o Finly Card através do nosso website, aplicativos móveis e serviços relacionados.
                </p>
            </Section>
            <Section>
                <h2>2. Informações Coletadas</h2>
                <p>
                    Dados Pessoais que Você Fornece:
                    Informações de Registro: Nome, endereço de e-mail, número de telefone, informações da empresa para a qual trabalha e cargo.
                    Informações Financeiras: Detalhes de contas bancárias, transações, histórico de crédito e outras informações financeiras necessárias para a prestação de nossos serviços de conciliação financeira.
                </p>
                <p>
                    Dados Coletados Automaticamente:

                    Logs de Acesso e Uso: Informações sobre como você acessa e usa nossos serviços, incluindo tipo de dispositivo, endereço IP, páginas visitadas e ações realizadas.
                    Cookies e Tecnologias Semelhantes: Utilizamos cookies para melhorar a experiência do usuário, personalizar conteúdo e anúncios, e analisar o tráfego em nosso site.

                </p>
            </Section>
            <Section>
                <h2>3. Uso das Informações</h2>
                <p>
                    Prestação de Serviços: Utilizamos suas informações para fornecer, manter e melhorar os serviços do Finly Card, processar transações e cumprir nossas obrigações contratuais.
                    Comunicação: Usamos suas informações para comunicar-nos sobre atualizações de serviços, alertas de segurança e mensagens administrativas.
                    Marketing e Publicidade: Podemos usar suas informações para desenvolver e exibir conteúdo e publicidade personalizados em relação aos nossos serviços em outras plataformas.
                </p>
            </Section>
            <Section>
                <h2>4. Compartilhamento de Informações</h2>
                <p>
                    Prestadores de Serviços: Compartilhamos informações com prestadores de serviços contratados para realizar serviços em nosso nome, como processamento de pagamentos, análise de dados, marketing e publicidade, e hospedagem de dados.
                    Requisitos Legais: Podemos divulgar suas informações se acreditarmos que tal ação é necessária para cumprir com uma obrigação legal, como responder a uma ordem judicial ou um processo legal.
                </p>
            </Section>
            <Section>
                <h2>5. Segurança dos Dados</h2>
                <p>
                    Implementamos medidas de segurança técnicas e administrativas para proteger suas informações contra acesso, alteração, divulgação ou destruição não autorizada.
                    Essas medidas incluem criptografia de dados, firewalls e controles de acesso físico e eletrônico.
                </p>
            </Section>
            <Section>
                <h2>6. Seus Direitos</h2>
                <p>
                    Você tem o direito de acessar, corrigir, excluir ou transferir suas informações pessoais que mantemos, bem como o direito de restringir ou objetar certos processamentos de suas informações.
                </p>
            </Section>
            <Section>
                <h2>7. Alterações à Política de Privacidade</h2>
                <p>
                    Reservamo-nos o direito de modificar esta política a qualquer momento. Se fizermos alterações, notificaremos você revisando a data no topo da política e, em alguns casos, podemos fornecer notificação adicional.
                </p>
            </Section>
            <Section>
                <h2>8. Contato</h2>
                <p>
                    Se tiver dúvidas ou preocupações sobre esta política ou suas informações pessoais, entre em contato conosco em: suporte@finlycard.com
                </p>
            </Section>
            <Footer>
                <p>© 2025 Todos os direitos reservados. Finly</p>
            </Footer>
        </Container>
    );
};

export default PoliticaPrivacidadeScreen;
