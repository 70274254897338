import { Link } from 'react-router-dom';
import { Button, Image, Input, Select, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CaretDownOutlined, CaretUpOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens, modalidades, statusConciliacao, statusTaxaAnalytics } from '../../../styles/globalInterfaces';
import moment from 'moment';
import { StyledSpan, StyledTag } from '../styled';

const { Option } = Select;
export const columnsVenda = (analytics: boolean, adquirentes: any[], bandeiras: any[], estabelecimentos: any[], selectedAdquirentes?: any[]): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Data Venda',
      dataIndex: 'VENDADATA',
      key: 'VENDADATA',
      sorter: true,
      render: (text: any) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'ESTABELECIMENTONUMERO',
      key: 'ESTABELECIMENTONUMERO',
      sorter: true,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o estabelecimento" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {estabelecimentos.map((estab: any) => (
              <Option key={estab[0]} value={estab}>
                {estab}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.ESTABELECIMENTONUMERO);
      },
    },
    {
      title: 'Adquirente',
      dataIndex: 'ADQUIRENTENOME',
      key: 'ADQUIRENTENOME',
      sorter: true,
      render: (text: string, record: any) => <Image preview={false} width={35} src={adquirenteNomeParaImagem[record.ADQUIRENTENOME.toLowerCase().replace(/\s/g, '')]} alt={record.ADQUIRENTENOME} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Selecione a adquirente"
            style={{ width: 200, marginBottom: 8, display: 'block' }}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            disabled={selectedAdquirentes ? selectedAdquirentes.length > 0 : false}
          >
            {adquirentes.map((adq: any) => (
              <Option key={adq.adquirenteid} value={adq.adquirentenome}>
                {adq.adquirentenome}
              </Option>
            ))}
          </Select>
          <Button disabled={selectedAdquirentes ? selectedAdquirentes.length > 0 : false} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={selectedAdquirentes ? selectedAdquirentes.length > 0 : false}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.ADQUIRENTENOME);
      },
    },
    {
      title: 'Bandeira',
      dataIndex: 'BANDEIRANOME',
      key: 'BANDEIRANOME',
      sorter: true,
      render: (text: string, record: any) => (
        <Tooltip title={record.BANDEIRANOME} placement="left">
          <div>
            <Image width={25} src={bandeiraNomeParaImagens[record.BANDEIRANOME.toLowerCase()]} alt={record.BANDEIRANOME} preview={false} />
          </div>
        </Tooltip>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione a bandeira" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {bandeiras.map((bandeira: any) => (
              <Option key={bandeira.bandeiraid} value={bandeira.bandeiranome}>
                {bandeira.bandeiranome}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.BANDEIRANOME);
      },
    },
    {
      title: 'Autorização',
      dataIndex: 'VENDAAUTORIZACAO',
      key: 'VENDAAUTORIZACAO',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        return record.VENDAAUTORIZACAO.includes(value);
      },
      render: (text, record) => text,
    },
    {
      title: 'TID',
      dataIndex: 'VENDATID',
      key: 'VENDATID',
      sorter: true,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.VENDATID);
      },
    },
    {
      title: 'RO',
      dataIndex: 'VENDARO',
      key: 'VENDARO',
      sorter: true,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.VENDARO);
      },
    },
    {
      title: 'NSU',
      dataIndex: 'VENDANSU',
      key: 'VENDANSU',
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.VENDANSU.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Valor Bruto',
      dataIndex: 'VENDAVALORBRUTO',
      key: 'VENDAVALORBRUTO',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        const formattedValue = value.replace('R$', '').trim().replace(/\./g, '').replace(',', '.');
        const numericValue = parseFloat(record.VENDAVALORBRUTO); 
        return numericValue === parseFloat(formattedValue);
      },      
      render: (value: any) => (
        <div>
          <span>{parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
        </div>
      ),
    },
    {
      title: 'Valor Líquido',
      dataIndex: 'VENDAVALORLIQUIDO',
      key: 'VENDAVALORLIQUIDO',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        const formattedValue = value.replace('R$', '').trim().replace(/\./g, '').replace(',', '.');
        const numericValue = parseFloat(record.VENDAVALORLIQUIDO); 
        return numericValue === parseFloat(formattedValue);
      }, 
      render: (value: any) => (
        <div>
          <span>{parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
        </div>
      ),
    },
    {
      title: 'Taxa',
      dataIndex: 'VENDATAXA',
      key: 'VENDATAXA',
      sorter: true,
      render: (text, record) => <div>{text}%</div>,
    },
    {
      title: 'Modalidade',
      dataIndex: 'MODALIDADEID',
      key: 'MODALIDADEID',
      sorter: true,
      render: (text: string, record: any) => <Tag>{record.MODALIDADENOME}</Tag>,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione a modalidade" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {modalidades.map((modalidade: any) => (
              <Option key={modalidade.id} value={modalidade.id}>
                {modalidade.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.MODALIDADEID);
      },
    },
    {
      title: 'Status',
      dataIndex: 'VENDASTATUSCONCILIACAO',
      key: 'VENDASTATUSCONCILIACAO',
      sorter: true,
      render: (text: string, record: any) => <Tag>{record.VENDASTATUSCONCILIACAO === 0 ? 'NÃO CONCILIADO' : record.VENDASTATUSCONCILIACAO === 1 ? 'CONCILIADO' : record.VENDASTATUSCONCILIACAO === 3  ? 'CANCELADO' : record.VENDASTATUSCONCILIACAO === 4 ? 'CANCELADO' : record.VENDASTATUSCONCILIACAO === 2 ? 'ATRASADO' : record.VENDASTATUSCONCILIACAO === 5 ? 'CANCELADO PARCIALMENTE' :'STATUS DESCONHECIDO'}</Tag>,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o status" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value !== undefined ? [value] : [])}>
          {statusConciliacao
              .filter((status: any) => status.id !== 2)
              .map((status: any) => (
                <Option key={status.id} value={status.id}>
                  {status.name}
                </Option>
              ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.VENDASTATUSCONCILIACAO);
      },
    },
  ];

  if (analytics) {
    columns.push({
      title: '% Contrato',
      dataIndex: 'GESTAOTAXAVALORTAXA',
      key: 'GESTAOTAXAVALORTAXA',
      render: (text: string, record: any) => <>{record.GESTAOTAXAVALORTAXA ? record.GESTAOTAXAVALORTAXA + '%' : 'N/A'}</>,
    });

    columns.push({
      title: 'Diferença',
      dataIndex: 'DIFERENCA_PERCENTUAL',
      key: 'DIFERENCA_PERCENTUAL',
      render: (text, record) => {
        if (record.DIFERENCA_PERCENTUAL !== null) {
          const diferenca = parseFloat(record.DIFERENCA_PERCENTUAL);
          const isGood = diferenca < 0;
          const isNeutral = diferenca === 0;
          const diferencaFormatada = diferenca.toFixed(2);
          let color = 'red';
          if (isGood) {
            color = 'green';
          } else if (isNeutral) {
            color = 'grey';
          }
          return <StyledSpan style={{ color }}>{diferenca > 0 ? `+${diferencaFormatada}` : diferencaFormatada}%</StyledSpan>;
        } else {
          return <span>N/A</span>;
        }
      },
    });

    columns.push({
      title: 'Potencial',
      dataIndex: 'POTENCIAL',
      key: 'POTENCIAL',
      render: (text, record) => {
        if (record.POTENCIAL !== null) {
          const diferenca = parseFloat(record.POTENCIAL);
          if (diferenca < 0) {
            return (
              <span style={{ color: 'green' }}>
                <CaretUpOutlined /> R$ {Math.abs(diferenca).toFixed(2)}
              </span>
            );
          } else if (diferenca > 0) {
            return (
              <span style={{ color: 'red' }}>
                <CaretDownOutlined /> R$ {diferenca.toFixed(2)}
              </span>
            );
          } else {
            return <span>{diferenca.toFixed(2)}</span>;
          }
        } else {
          return <span>N/A</span>;
        }
      },
    });

    columns.push({
      title: 'Situação',
      dataIndex: 'TAGTYPE',
      key: 'TAGTYPE',
      render: (text, record) => {
        switch (record.TAGTYPE) {
          case 'green':
            return <StyledTag color="green">Taxa Válida</StyledTag>;
          case 'orange':
            return (
              <Link to="/gestao-taxa">
                <StyledTag color="orange">Cadastrar Taxa</StyledTag>
              </Link>
            );
          case 'red':
            return (
              <StyledTag color="grey">
                <ExclamationCircleOutlined className="alert-icon" />
                Divergência de Taxa
              </StyledTag>
            );
          default:
            return <StyledTag>Não Definido</StyledTag>;
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o status" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {statusTaxaAnalytics.map((taxa: any) => (
              <Option key={taxa.id} value={taxa.id}>
                {taxa.name}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.TAGTYPE);
      },
    });
  }
  return columns;
};
