import { SearchOutlined } from '@ant-design/icons';
import { Button, Image, Input, Select, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens } from '../../../styles/globalInterfaces';

const { Option } = Select;
export const columnsIntegracaoVenda = (parceiros: any[], adquirente: any[], bandeiras: any[], estabelecimentos: any[], meiosCaptura: any[], modalidades: any[], statusConciliacao: any[]): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Código Único do Registro',
      dataIndex: 'integracaoid',
      key: 'integracaoid',
      sorter: true,
      width: 130,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180 }}>
          <Input
            placeholder="Pesquisar código identificador"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.integracaoid !== null && record.integracaoid.toString().includes(value.toString()),
    },
    {
      title: 'Nome Sistema',
      dataIndex: 'integracaoparceiroid',
      key: 'integracaoparceiroid',
      sorter: true,
      width: 80,
      render: (text: string, record: any) => {
        const parceiro = parceiros?.find((p) => p.id === record.integracaoparceiroid);
        if (parceiro) {
          return <span>{parceiro.nome}</span>;
        }
        return <span style={{ color: 'red' }}>Parceiro não encontrado</span>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Sistema" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {parceiros?.map((parc) => (
              <Option key={parc.id} value={parc.id}>
                {parc.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!parceiros || parceiros.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!parceiros || parceiros.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.integracaoparceiroid);
      },
    },
    {
      title: 'Código Identificador',
      dataIndex: 'integracaocodigoidentificador',
      key: 'integracaocodigoidentificador',
      sorter: true,
      width: 130,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180 }}>
          <Input
            placeholder="Pesquisar código identificador"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.integracaocodigoidentificador !== null && record.integracaocodigoidentificador.toString().includes(record.integracaocodigoidentificador.toString()),
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'estabelecimentonumero',
      key: 'estabelecimentonumero',
      sorter: true,
      width: 100,
      render: (text: string, record: any) => {
        const estabelecimento = estabelecimentos?.find((p) => p.id === record.integracaoestabelecimentoid);
        if (estabelecimento) {
          return <span>{estabelecimento.nome}</span>;
        }
        return <span style={{ color: 'red' }}>Estabelecimento não encontrado</span>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Estabelecimento" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {estabelecimentos?.map((parc) => (
              <Option key={parc.id} value={parc.id}>
                {parc.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!estabelecimentos || estabelecimentos.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!estabelecimentos || estabelecimentos.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.integracaoestabelecimentoid);
      },
    },
    {
      title: 'Data Venda',
      dataIndex: 'integracaodatavenda',
      key: 'integracaodatavenda',
      width: 100,
      sorter: true,
      render: (text: any) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Data Prev. Pagamento',
      dataIndex: 'integracaoprevisaopagamento',
      key: 'integracaoprevisaopagamento',
      width: 100,
      sorter: true,
      render: (text: any) => (text ? moment(text).format('DD/MM/YYYY') : <Tag>Não informado</Tag>),
    },
    {
      title: 'Autorização',
      dataIndex: 'integracaonumeroautorizacao',
      key: 'integracaonumeroautorizacao',
      sorter: true,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180 }}>
          <Input
            placeholder="Pesquisar número autorização"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.integracaonumeroautorizacao !== null && record.integracaonumeroautorizacao.toString().includes(record.integracaonumeroautorizacao.toString()),
    },
    {
      title: 'NSU',
      dataIndex: 'integracaonumeronsu',
      key: 'integracaonumeronsu',
      width: 50,
      sorter: true,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180 }}>
          <Input
            placeholder="Pesquisar código identificador"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.integracaonumeronsu !== null && record.integracaonumeronsu.toString().includes(record.integracaonumeronsu.toString()),
    },
    {
      title: 'TID',
      dataIndex: 'integracaonumerotid',
      key: 'integracaonumerotid',
      width: 50,
      sorter: true,
      render: (text, record) => text,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180 }}>
          <Input
            placeholder="Pesquisar código identificador"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.integracaonumerotid !== null && record.integracaonumerotid.toString().includes(record.integracaonumerotid.toString()),
    },
    {
      title: 'Adquirente',
      dataIndex: 'adquirenteid',
      key: 'adquirenteid',
      sorter: true,
      width: 110,
      render: (text: string, record: any) => <Image preview={false} width={35} src={adquirenteNomeParaImagem[record.adquirentenome.toLowerCase().replace(/\s/g, '')]} alt={record.adquirentenome} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Sistema" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {adquirente?.map((parc) => (
              <Option key={parc.id} value={parc.id}>
                {parc.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!adquirente || adquirente.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!adquirente || adquirente.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.adquirenteid);
      },
    },
    {
      title: 'Bandeira',
      dataIndex: 'bandeiraid',
      key: 'bandeiraid',
      sorter: true,
      width: 110,
      render: (text: string, record: any) => <Image preview={false} width={35} src={bandeiraNomeParaImagens[record.bandeiranome.toLowerCase()]} alt={record.bandeiranome} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Sistema" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {bandeiras?.map((band) => (
              <Option key={band.id} value={band.id}>
                {band.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!bandeiras || bandeiras.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!bandeiras || bandeiras.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.bandeiraid);
      },
    },
    {
      title: 'Valor Bruto Parcela',
      dataIndex: 'integracaovalorbrutoparcela',
      key: 'integracaovalorbrutoparcela',
      sorter: true,
      width: 120,
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 180 }}>
          <Input
            placeholder="Pesquisar pelo Valor"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 180, marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.integracaonumerotid !== null && record.integracaonumerotid.toString().includes(record.integracaonumerotid.toString()),
    },
    {
      title: 'Valor Líquido Parcela',
      dataIndex: 'integracaovalorliquidoparcela',
      key: 'integracaovalorliquidoparcela',
      sorter: true,
      width: 125,
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
    },
    {
      title: 'Valor Total',
      dataIndex: 'integracaovalortotal',
      key: 'integracaovalortotal',
      sorter: true,
      width: 100,
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
    },
    {
      title: 'Taxa',
      dataIndex: 'integracaotaxavenda',
      key: 'integracaotaxavenda',
      sorter: true,
      width: 60,
      render: (text, record) => <div>{text}%</div>,
    },
    {
      title: 'Parcela',
      dataIndex: 'integracaonumeroparcela',
      key: 'integracaonumeroparcela',
      sorter: true,
      width: 60,
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Total de Parcelas',
      dataIndex: 'integracaototalparcelas',
      key: 'integracaototalparcelas',
      sorter: true,
      width: 80,
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Terminal',
      dataIndex: 'integracaonumeroterminal',
      key: 'integracaonumeroterminal',
      sorter: true,
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Meio de Captura',
      dataIndex: 'meiocapturaid',
      key: 'meiocapturaid',
      sorter: true,
      width: 110,
      render: (text: string, record: any) => {
        const meiocaptura = meiosCaptura?.find((p) => p.id === record.meiocapturaid);
        if (meiocaptura) {
          return <Tag>{meiocaptura.nome}</Tag>;
        }
        return <span style={{ color: 'red' }}>Parceiro não encontrado</span>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Sistema" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {meiosCaptura?.map((parc) => (
              <Option key={parc.id} value={parc.id}>
                {parc.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!meiosCaptura || meiosCaptura.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!meiosCaptura || meiosCaptura.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.meiocapturaid);
      },
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidadeid',
      key: 'modalidadeid',
      sorter: true,
      width: 100,
      render: (text: string, record: any) => {
        const modalidade = modalidades?.find((p) => p.id === record.modalidadeid);
        if (modalidade) {
          return <Tag>{modalidade.nome}</Tag>;
        }
        return <span style={{ color: 'red' }}>Parceiro não encontrado</span>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Sistema" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {modalidades?.map((parc) => (
              <Option key={parc.id} value={parc.id}>
                {parc.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!modalidades || modalidades.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!modalidades || modalidades.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.modalidadeid);
      },
    },
    {
      title: 'Status',
      dataIndex: 'integracaostatusconciliacao',
      key: 'integracaostatusconciliacao',
      sorter: true,
      width: 100,
      render: (text: string, record: any) => {
        const statusconciliacao = statusConciliacao?.find((p) => p.id === record.integracaostatusconciliacao);
        if (statusconciliacao) {
          return <Tag>{statusconciliacao.nome}</Tag>;
        }
        return <span style={{ color: 'red' }}>Parceiro não encontrado</span>;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione o Sistema" style={{ width: 180, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {statusConciliacao?.map((parc) => (
              <Option key={parc.id} value={parc.id}>
                {parc.nome}
              </Option>
            ))}
          </Select>
          <Button disabled={!statusConciliacao || statusConciliacao.length === 0} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            disabled={!statusConciliacao || statusConciliacao.length === 0}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        return value.includes(record.integracaostatusconciliacao);
      },
    },
  ];
  return columns;
};
