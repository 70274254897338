import { Reducer } from 'react';
import {
  FETCH_BANKING_OFX_DATA,
  FETCH_BANKING_OFX_FAILURE,
  FETCH_BANKING_OFX_SUCCESS,
  FETCH_UPLOAD_OFX_DATA,
  FETCH_UPLOAD_OFX_FAILURE,
  FETCH_UPLOAD_OFX_SUCCESS,
  RESET_UPLOAD_STATUS,
  FETCH_BANKING_OFX_PDF,
  FETCH_BANKING_OFX_PDF_SUCCESS,
  FETCH_BANKING_OFX_PDF_FAILURE,
  FETCH_BANKING_OFX_XLS,
  FETCH_BANKING_OFX_XLS_SUCCESS,
  FETCH_BANKING_OFX_XLS_FAILURE,
} from './types';

const bankingOfxState = {
  data: [],
  status_upload: [],
  loading: false,
  loading_upload: false,
  error: null,
  loadingBankingOfxExport: false,
};

export const reducer: Reducer<any, any> = (state = bankingOfxState, action: any) => {
  switch (action.type) {
    case FETCH_BANKING_OFX_DATA:
      return { ...state, loading: true };
    case FETCH_BANKING_OFX_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case FETCH_BANKING_OFX_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_UPLOAD_OFX_DATA:
      return { ...state, loading_upload: true };
    case FETCH_UPLOAD_OFX_SUCCESS:
      return {
        ...state,
        loading_upload: false,
        status_upload: [...state.status_upload, { status: action.payload.status, name: action.meta.file.name, data: action.payload.data }],
      };
    case FETCH_UPLOAD_OFX_FAILURE:
      return {
        ...state,
        loading_upload: false,
        error: action.payload,
        status_upload: [...state.status_upload, { status: false, name: action.meta.file.name, data: null }],
      };
    case RESET_UPLOAD_STATUS:
      return { ...state, status_upload: [] };
    case FETCH_BANKING_OFX_PDF:
      return { ...state, loadingBankingOfxExport: true };
    case FETCH_BANKING_OFX_PDF_SUCCESS:
      return { ...state, loadingBankingOfxExport: false };
    case FETCH_BANKING_OFX_PDF_FAILURE:
      return { ...state, loadingBankingOfxExport: false };
    case FETCH_BANKING_OFX_XLS:
      return { ...state, loadingBankingOfxExport: true };
    case FETCH_BANKING_OFX_XLS_SUCCESS:
      return { ...state, loadingBankingOfxExport: false };
    case FETCH_BANKING_OFX_XLS_FAILURE:
      return { ...state, loadingBankingOfxExport: false };

    default:
      return state;
  }
};
