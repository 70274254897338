import { useDispatch } from 'react-redux';
import { NotificationItem } from './interfaces';
import { NotificationItemContainer, StyledDate, StyledDescription, StyledList, StyledListItem, StyledTitle } from './styled';
import { updateNotification } from '../../store/states/notificacao/actions';
import moment from 'moment';

const NotificationList = ({ notifications }: { notifications: NotificationItem[]; }) => {
  const dispatch = useDispatch();

  const handleNotificationClick = (notificationId: number) => {
    dispatch(updateNotification({ notification_id: notificationId, status: 0 }));
  };

  return (
    <StyledList
      size="small"
      bordered
      dataSource={notifications}
      renderItem={(item: any) => (
        <StyledListItem onClick={() => handleNotificationClick(item.notificacaoid)}>
          <NotificationItemContainer>
            <StyledTitle ellipsis={{ tooltip: item.notificacaotitulo }}>{item.notificacaotitulo}</StyledTitle>
            <StyledDescription>{item.notificacaodescricao}</StyledDescription>
            <StyledDate>{moment(item.notificacaocreatedat).format('DD/MM/YYYY HH:mm:ss')}</StyledDate>
          </NotificationItemContainer>
        </StyledListItem>
      )}
    >
      {notifications.length === 0 && (
        <StyledListItem>
          <NotificationItemContainer>
            <StyledTitle>Não existem novas notificações</StyledTitle>
          </NotificationItemContainer>
        </StyledListItem>
      )}
    </StyledList>
  );
};

export default NotificationList;
