import { takeLatest, call, put } from 'redux-saga/effects';
import { notificationApi, updateNotificationApi } from '../../target-api/api';
import { fetchNotification, fetchNotificationSuccess, updateNotificationFailure, updateNotificationSuccess } from './actions';
import { FETCH_NOTIFICATION_DATA, UPDATE_NOTIFICATION } from './types';
import { AnyAction } from 'redux';
import { message } from 'antd';

export function* fetchNotificationSaga(action: any) {
  try {
    const data: any[] = yield call(notificationApi, action.payload);
    if (data !== undefined) {
      yield put(fetchNotificationSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de notificações!');
    }
  } catch (error: any) {
    message.error(error);
  }
}

export function* updateNotificationSaga(action: AnyAction): Generator {
  try {
    const { notification_id, status } = action.payload;
    const data: any = yield call(updateNotificationApi, notification_id, status);
    if (data) {
      yield put(updateNotificationSuccess(data));
      message.info(data.message);
      yield put(fetchNotification());
    } else {
      message.warning('Falha ao atualizar notificação!');
    }
  } catch (error: any) {
    yield put(updateNotificationFailure(error));
    message.error('Erro ao atualizar notificação!');
  }
}

export function* watchNotificationSaga() {
  yield takeLatest(FETCH_NOTIFICATION_DATA, fetchNotificationSaga);
  yield takeLatest(UPDATE_NOTIFICATION, updateNotificationSaga);
}
