import React from 'react';
import { Modal, List, Card, Collapse, Row, Col, Typography } from 'antd';
import { GlobalOutlined, MailOutlined, PhoneOutlined, IdcardOutlined } from '@ant-design/icons';
import { ModalDisplay } from './styled';

const { Panel } = Collapse;
const { Text } = Typography;

const ModalEmpresa: React.FC<any> = ({ isOpen, empresas, onClose }) => {
  const cardStyle = {
    borderRadius: '10px',
    marginBottom: '15px',
  };

  const iconStyle = {
    color: '#1890ff',
    marginRight: '5px',
  };

  return (
    <ModalDisplay>
      <Modal visible={isOpen} onCancel={onClose} footer={null} width={800} title="Dados da(s) Empresa(s)">
        <List
          dataSource={empresas}
          renderItem={(empresa: any) => (
            <Card style={cardStyle}>
              <Collapse accordion>
                <Panel header={empresa.empresanome} key={empresa.empresaid}>
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Text>
                        <IdcardOutlined style={iconStyle} /> Nome da Empresa:
                      </Text>
                      <Text strong> {empresa.empresanome}</Text>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Text>
                        <GlobalOutlined style={iconStyle} /> Documento:
                      </Text>
                      <Text strong> {empresa.empresadocumento}</Text>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: '10px' }}>
                    <Col xs={24} sm={12}>
                      <Text>
                        <MailOutlined style={iconStyle} /> E-mail:
                      </Text>
                      <Text strong> {empresa.empresaemail}</Text>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Text>
                        <PhoneOutlined style={iconStyle} /> Contato:
                      </Text>
                      <Text strong> {empresa.empresatelefone}</Text>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Card>
          )}
        />
      </Modal>
    </ModalDisplay>
  );
};

export default ModalEmpresa;
