import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  --header-color: linear-gradient(135deg, #252f64, #536390);
  --header-text-color: #ffffff;
  --active-header-text-color: #000000;

  .ant-table {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .ant-table-thead > tr > th {
    background-image: var(--header-color);
    color: var(--header-text-color);
    font-weight: bold;
    font-size: 14px;
    border-bottom: 2px solid #ffffff;
  }

  .ant-table-thead > tr > th:hover {
    background-color: var(--header-hover-color);
    color: #252f64;
    cursor: pointer;
  }

  .ant-table-thead > tr > th.ant-table-column-sort:hover {
    background-color: var(--header-hover-color);
    color: #252f64;
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    color: var(--active-header-text-color);
  }

  .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: var(--odd-row-color);
  }

  .ant-table-tbody > tr:nth-child(even) > td {
    background-color: var(--even-row-color);
  }

  .ant-table-tbody > tr > td {
    color: #252f64 !important;
    font-size: 12px;
    font-weight: 500;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #cbcedd !important;
    transition: background-color 0.1s ease, color 0.1s ease, border-color 0.1s ease;
}

  .ant-table-row {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .ant-table-expand-icon-th,
  .ant-table-row-expand-icon {
    display: none !important;
  }

  .ant-table-row-expand-icon {
    display: none !important;
  }

  tr.ant-table-expanded-row > td:nth-child(1) {
    background-color: #cbcedd 
  }
`;

