import api from '../../../services/axios';

export async function enviarPagamentoApi(payload: any): Promise<any> {
  try {
    const response = await api.post('/envio-remessa', payload, {
      baseURL: process.env.REACT_APP_INTEGRACAO_API,
      validateStatus: (status) => status >= 200 && status < 500,
    });
    if (response.status !== 200) {
      throw new Error(`Erro na requisição: ${response.status} - ${JSON.stringify(response.data)}`);
    }
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.detail || 'Erro ao enviar pagamento');
  }
}
