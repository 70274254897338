import styled from 'styled-components';
import { Button, ButtonProps, Typography } from 'antd';

const { Title: AntTitle, Paragraph: AntParagraph } = Typography;

export const StyledTitle = styled(AntTitle)`
  color: #c2c2c2 !important;
`;

export const StyledParagraph = styled(AntParagraph)`
  color: #c2c2c2 !important;
  font-size: 16px;
`;

interface AppLaunchBannerProps {
  isMinimized: boolean;
}

interface MinimizeButtonProps extends ButtonProps {
  isMinimized: boolean;
}

export const AppLaunchBanner = styled.div<AppLaunchBannerProps>`
  background: linear-gradient(135deg, #252f64, #1e204e);
  padding: ${props => (props.isMinimized ? '5px 20px' : '20px')};
  text-align: center;
  border-radius: 12px;
  margin: 15px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: ${props => (props.isMinimized ? '10px' : '300px')};
  opacity: ${props => (props.isMinimized ? 0.5 : 1)};
  transition: all 0.5s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

export const MinimizeButton = styled(Button) <MinimizeButtonProps>`
  position: absolute;
  top: ${props => (props.isMinimized ? '0px' : '10px')};
  right: 10px;
  border: none;
  color: white;
  background: none;
  &:hover {
    color: #202855;
  }
`;
