import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Image, message, Typography } from 'antd';
import img01 from '../../assets/landing-page/img01.png';
import img02 from '../../assets/landing-page/img02.png';
import img03 from '../../assets/landing-page/img03.png';
import img04 from '../../assets/landing-page/img04.png';
import img05 from '../../assets/landing-page/img05.png';
import img07 from '../../assets/landing-page/img07.png';
import img08 from '../../assets/landing-page/img08.png';
import img09 from '../../assets/landing-page/img09.png';
import finlyLogo from '../../assets/logo.png';
import finlyCard from '../../assets/login/logo-card-union.svg';
import finlyLogoText from '../../assets/finly.svg';
import ModalRateProposal from '../../components/Modals/ModalProposal';
import {
  Content,
  LandingButton,
  StyledGroupFinlyImage,
  Footer,
  SocialMediaIcons,
  ProposalButtonContainer,
  FlexContainer,
  FlexItem,
  StyledParagraph,
  StyledImage,
  FlexItemStatic,
  ButtonBackToTop,
  StoreLink,
  StoreIcon,
} from './styled';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { LoginOutlined, RocketOutlined, UpCircleOutlined } from '@ant-design/icons';
import appStoreIcon from '../../assets/landing-page/apple.svg';
import googlePlayIcon from '../../assets/landing-page/googleplay.svg';

const { Paragraph } = Typography;

const LandingPage: React.FC = () => {
  const [IsModalVisible, setIsModalVisible] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const history = useHistory();
  const redirectionPageLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const showMessage = () => {
    message.info('Card Mobile em breve para iOS, aguarde que logo estará disponível!');
  };

  return (
    <>
      <Content>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ left: '25%', paddingTop: '3%' }}>
          <LandingButton type="primary" shape="default" onClick={redirectionPageLogin}>
            <LoginOutlined />Acesse agora
          </LandingButton>
        </Col>
        <FlexContainer>
          <StyledGroupFinlyImage>
            <StyledImage preview={false} alt="Finly Tech" src={finlyCard} />
          </StyledGroupFinlyImage>
          <FlexItemStatic>
          </FlexItemStatic>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledParagraph>
              Conciliação completa de operações <br />de cartões de crédito, débito, pix,<br /> antecipações e recebíveis.
            </StyledParagraph>
          </FlexItem>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img01} />
            </StyledGroupFinlyImage>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img04} />
            </StyledGroupFinlyImage>
          </FlexItem>
          <FlexItem>
            <StyledParagraph>
              <>O que é Finly Card?</>
              <Paragraph style={{ color: '#dfdfdf' }}>
                <>
                  O FINLY CARD é um sistema especializado em auditoria para as <br />
                  operações das vendas de cartão de crédito, débito e vouchers dos
                  nossos clientes.<br /> A solução também oferece a gestão e conciliação automatizada em tempo real.
                </>
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledParagraph>
              Como conciliamos?
              <Paragraph style={{ color: '#dfdfdf' }}>
                <>
                  Com os dados das operadoras, nós conciliamos suas vendas de
                  forma automática. <br />Utilizamos o mais alto nível de tecnologia em processamentos e segurança, para que seus <br />
                  dados ﬁquem totalmente seguros.
                </>
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img03} />
            </StyledGroupFinlyImage>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img05} />
            </StyledGroupFinlyImage>
          </FlexItem>
          <FlexItem>
            <StyledParagraph>
              Auditoria de Taxas
              <Paragraph style={{ color: '#dfdfdf' }}>
                <>
                  A auditoria de taxas é um processo crucial para garantir a precisão das <br />
                  transações financeiras do seu negócio. Com o nosso sistema, você pode <br />
                  cadastrar o contrato do cliente, definindo modalidades, cartões, bandeiras, <br />
                  taxas e períodos de vigência. Após o cadastro, nossa ferramenta analisa <br />
                  as transações para identificar se as taxas aplicadas estão em conformidade <br />
                  com o contrato. No módulo de analytics, você pode visualizar as taxas <br />
                  auditadas, destacando as que estão corretas e alertando sobre possíveis <br />
                  erros ou fraudes. Garanta a integridade financeira do seu negócio com <br />
                  nossa auditoria de taxas.
                </>
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledParagraph>
              Conciliação bancária
              <Paragraph style={{ color: '#dfdfdf' }}>
                Você no controle de suas vendas, conferindo se todos os <br />
                pagamentos realizados pelas operadoras foram autorizados <br />
                corretamente em sua conta bancária.
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img02} />
            </StyledGroupFinlyImage>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img03} />
            </StyledGroupFinlyImage>
          </FlexItem>
          <FlexItem>
            <StyledParagraph>
              Conferência de vendas
              <Paragraph style={{ color: '#dfdfdf' }}>
                De forma simples e automatizada, conferimos todas as vendas <br />
                lançadas na maquineta (TEF/POS) e confrontamos com a <br />
                operadora sobre as vendas lançadas. Com isso o cliente evita <br />
                que a operadora deixe de capturar alguma venda por eventual <br />
                falha na comunicação e tenha certeza de que todas as suas <br />
                vendas foram capturadas.
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledParagraph>
              Gestão econômica
              <Paragraph style={{ color: '#dfdfdf' }}>
                Você no controle de suas vendas, conferindo se todos os <br />
                pagamentos realizados pelas operadoras foram autorizados <br />
                corretamente em sua conta bancária.
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img09} />
            </StyledGroupFinlyImage>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img07} />
            </StyledGroupFinlyImage>
          </FlexItem>
          <FlexItem>
            <StyledParagraph>
              Integração avançada
              <Paragraph style={{ color: '#dfdfdf' }}>
                Integração com qualquer plataforma ou sistema <br />
                para que possa realizar sua conciliação financeira <br />
                de forma assertiva e segura.
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <StyledParagraph>
              Principais benefícios
              <Paragraph style={{ color: '#dfdfdf' }}>
                <>
                  - Diminuição dos seus custos com taxas e maquinetas <br />
                  - Economia no tempo e equipe nas conferências <br />
                  - Evitar cobranças incorretas e atrasos de <br />
                  &nbsp;&nbsp;&nbsp;pagamentos <br />
                  - Informação sobre as taxas e os custos operacionais <br />
                  - Conferência se todas as suas vendas se foram <br />
                  &nbsp;&nbsp;&nbsp;registradas pelas operadoras <br />
                  - Monitoramento diário dos seus pagamentos <br />
                  - Acesso ao painel gerencial com múltiplas empresas <br />
                  - Integração com qualquer ERP, TEF ou outros sistemas <br />
                  - Importação das vendas diretamente das operadoras <br />- Conciliação com os principais bancos e
                  operadoras do mercado
                </>
              </Paragraph>
            </StyledParagraph>
          </FlexItem>
          <FlexItem>
            <StyledGroupFinlyImage>
              <StyledImage preview={false} alt="Finly Tech" src={img08} />
            </StyledGroupFinlyImage>
          </FlexItem>
        </FlexContainer>
        <ProposalButtonContainer>
          <LandingButton onClick={showModal} type="primary" shape="default">
            <RocketOutlined style={{ fontSize: '24px' }} />Solicite nosso contato!
          </LandingButton>
        </ProposalButtonContainer>
      </Content>
      <Footer>
        {showTopBtn && (
          <ButtonBackToTop onClick={scrollToTop}><UpCircleOutlined style={{ fontSize: '24px' }} /> Voltar ao Topo</ButtonBackToTop>
        )}
        <SocialMediaIcons>
          <a href="https://www.instagram.com/finlytech/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://finlytech.com" target="_blank" rel="noopener noreferrer"><Image width={25} src={finlyLogo} preview={false} style={{ cursor: 'pointer', marginTop: -15 }} /></a>
          <a href="https://br.linkedin.com/company/finly-tech" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
        </SocialMediaIcons>
        © 2025 Todos os direitos reservados.
        <div style={{ marginTop: '20px' }}>
          <a href="https://finlytech.com" target="_blank" rel="noopener noreferrer">
            <Image src={finlyLogoText} alt="Finly Logo" width={50} preview={false} style={{ cursor: 'pointer' }} />
          </a>
        </div>
        <StoreLink href="https://play.google.com/store/apps/details?id=com.card.app.card_app&hl=pt_BR" target="_blank" rel="noopener noreferrer">
          <StoreIcon preview={false} src={googlePlayIcon} alt="Get it on Google Play" />
        </StoreLink>
        <StoreLink href="#" onClick={showMessage} rel="noopener noreferrer">
          <StoreIcon preview={false} src={appStoreIcon} alt="Download on the App Store" />
        </StoreLink>
      </Footer>
      <ModalRateProposal isOpen={IsModalVisible} onClose={handleModalClose} />
    </>
  );
};
export default LandingPage;
