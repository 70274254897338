import { takeLatest, call, put } from 'redux-saga/effects';
import { proposalApi, recoverPasswordApi, resetPasswordApi, uploadPhotoApi, userProfileApi } from '../../target-api/api';
import { message } from 'antd';
import { fetchUserProfileFailure, fetchUserProfileSuccess, proposalSuccess, recoverPasswordSuccess, resetPasswordSuccess } from './actions';
import history from '../../../services/history';
import { FETCH_USER_PROFILE, PROPOSTA, RECOVER_PASSWORD, RESET_PASSWORD, UPLOAD_USER_PHOTO } from './types';

export function* fetchUserProfileSaga(): Generator<any, void, any> {
  try {
    const response = yield call(userProfileApi);
    yield put(fetchUserProfileSuccess(response));
  } catch (error: any) {
    message.error('Falha ao buscar o perfil do usuário.');
    yield put(fetchUserProfileFailure(error.toString()));
  }
}

export function* recoverPasswordSaga(action: any) {
  try {
    const { subdomain, email } = action.payload;
    yield call(recoverPasswordApi, subdomain, email);
    yield put(recoverPasswordSuccess());
    history.push('/');
  } catch (error: any) {
    message.warning('Não foi possível enviar a recuperação de e-mail, revise os dados e tente novamente!');
    yield put(recoverPasswordSuccess());
  }
}

export function* resetPasswordSaga(action: any) {
  try {
    const { subdomain, token, password } = action.payload;
    yield call(resetPasswordApi, subdomain, token, password);
    yield put(resetPasswordSuccess());
    history.push('/');
  } catch (error: any) {
    message.warning('Não foi possível resetar a recuperação de e-mail, revise os dados e tente novamente!');
    yield put(recoverPasswordSuccess());
  }
}

export function* proposalSaga(action: any) {
  try {
    const { proposal } = action.payload;
    yield call(proposalApi, proposal);
    yield put(proposalSuccess());
  } catch (error: any) {
    message.warning('Não foi possível enviar a proposta, revise os dados e tente novamente!');
    yield put(proposalSuccess());
  }
}

export function* uploadPhotoSaga(action: any) {
  try {
    const { file } = action.payload;
    const formData = new FormData();
    formData.append('image', file.originFileObj);
    yield call(uploadPhotoApi, formData);
    message.success('Foto atualizada com sucesso!');
  } catch (error) {
    message.error('Erro ao atualizar a foto!');
  }
}

export function* watchUsuarioSaga() {
  yield takeLatest(FETCH_USER_PROFILE, fetchUserProfileSaga);
  yield takeLatest(RECOVER_PASSWORD, recoverPasswordSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(PROPOSTA, proposalSaga);
  yield takeLatest(UPLOAD_USER_PHOTO, uploadPhotoSaga);
}
