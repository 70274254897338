export const FETCH_BANKING_OFX_DATA = 'FETCH_BANKING_OFX_DATA'
export const FETCH_BANKING_OFX_SUCCESS = 'FETCH_BANKING_OFX_SUCCESS'
export const FETCH_BANKING_OFX_FAILURE = 'FETCH_BANKING_OFX_FAILURE'
export const FETCH_UPLOAD_OFX_DATA = 'FETCH_UPLOAD_OFX_DATA'
export const FETCH_UPLOAD_OFX_SUCCESS = 'FETCH_UPLOAD_OFX_SUCCESS'
export const FETCH_UPLOAD_OFX_FAILURE = 'FETCH_UPLOAD_OFX_FAILURE'
export const RESET_UPLOAD_STATUS = 'RESET_UPLOAD_STATUS';
export const FETCH_BANKING_OFX_PDF = 'FETCH_BANKING_OFX_PDF'
export const FETCH_BANKING_OFX_PDF_SUCCESS = 'FETCH_BANKING_OFX_PDF_SUCCESS'
export const FETCH_BANKING_OFX_PDF_FAILURE = 'FETCH_BANKING_OFX_PDF_FAILURE'
export const FETCH_BANKING_OFX_XLS = 'FETCH_BANKING_OFX_XLS'
export const FETCH_BANKING_OFX_XLS_SUCCESS = 'FETCH_BANKING_OFX_XLS_SUCCESS'
export const FETCH_BANKING_OFX_XLS_FAILURE = 'FETCH_BANKING_OFX_XLS_FAILURE'