import { Dropdown } from 'antd';
import { keyframes, styled } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    background: linear-gradient(135deg, #252f64, #536390);
    color: white;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    animation: ${fadeIn} 0.5s ease-out;
  }

  .ant-dropdown-menu-item {
    transition: background 0.3s, color 0.3s, transform 0.3s;
  }

  .ant-dropdown-menu-item:hover {
    background: linear-gradient(135deg, #536390, #252f64);
    color: #d1d1d1;
    transform: scale(1.05);
  }

  .ant-dropdown-menu-item:active {
    background: linear-gradient(135deg, #252f64, #536390);
  }
`;
