import Lottie from 'react-lottie';
import animationData from '../../assets/loadings/loading_aspiral.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const CustomLoading = () => (
    <div style={{ textAlign: 'center', marginTop: '10%' }}>
        <Lottie options={defaultOptions} height={150} width={150} />
    </div>
);

export default CustomLoading;