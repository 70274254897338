import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const Header = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

export const Section = styled.section`
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #eee;

  h2 {
    color: #222;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    line-height: 1.6;
  }
`;

export const Footer = styled.footer`
  text-align: center; 
  margin-top: 40px;
  padding: 20px;
  font-size: 0.9rem;
  color: #777;
`;

