import { PlusOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, InputNumber, notification, Select } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonInsertRecord from '../../components/Common/ButtonInsertRecord';
import { fetchAdquirente } from '../../store/states/adquirente/actions';
import { fetchBandeira } from '../../store/states/bandeira/actions';
import { fetchEstabelecimento } from '../../store/states/estabelecimento/actions';
import { fetchModalidade } from '../../store/states/modalidade/actions';
import { enviarPagamentoApi } from '../../store/target-api/endpoints/uploadArquivoIntegracao';
import { RootState } from '../../store/types';
import { ButtonConcluir, CustomConteinerFormModal, CustomConteinerFormModalDiv, CustomFooterButtonsContainer, CustomModal } from './styled';
const { Option } = Select;
dayjs.extend(isSameOrAfter);

interface Empresa {
  empresaid: number;
  empresanome: string;
}

interface EmpresaReduxState {
  companyID: number[];
  data: Empresa[];
}

interface Adquirente {
  adquirenteid: number;
  adquirentenome: string;
  adquirenteref: string;
}

interface AdquirenteReduxState {
  data: Adquirente[];
}

interface Modalidade {
  modalidadeid: number;
  modalidadenome: string;
}

interface ModalidadeRedux {
  data: Modalidade[];
}

interface Bandeira {
  bandeiraid: number;
  bandeiranome: string;
}

interface BandeiraRedux {
  data: Bandeira[];
}

interface Estabelecimento {
  estabelecimentoid: number;
  estabelecimentonumero: string;
  estabelecimentoempresaid: number;
  estabelecimentoadquirenteid: number;
}

interface ValidationError {
  errorFields: any[];
}

const PopupInserirDados: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedEstabelecimentoAdquirenteId, setSelectedEstabelecimentoAdquirenteId] = useState<number | null>(null);
  const empresasRedux = useSelector<RootState, EmpresaReduxState>((state) => state.empresa || { companyID: [], data: [] });
  const empresa = (empresasRedux?.data || []).filter((empresa: Empresa) => (empresasRedux.companyID ?? []).includes(empresa.empresaid));
  const adquirentesRedux = useSelector<RootState, AdquirenteReduxState>((state) => state.adquirente || { data: [] });
  const adquirentes = adquirentesRedux.data || [];
  const modalidadeRedux = useSelector<RootState, ModalidadeRedux>((state) => state.modalidade || { data: [] });
  const modalidade = modalidadeRedux.data || [];
  const bandeiraRedux = useSelector<RootState, BandeiraRedux>((state) => state.bandeira || { data: [] });
  const bandeira = bandeiraRedux.data || [];
  const estabelecimento = useSelector((state: RootState) => state.estabelecimento.data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchModalidade());
    dispatch(fetchAdquirente());
    dispatch(fetchBandeira());
  }, [dispatch]);

  const handleChangeEmpresa = (empresa_id: any) => {
    dispatch(fetchEstabelecimento({ empresa_id: empresa_id }));
  };

  const handleChangeEstabelecimento = (value: any) => {
    const selectedEstabelecimento = estabelecimento.find((estab: any) => estab.estabelecimentoid === value);

    if (selectedEstabelecimento) {
      const adquirente = adquirentes.find((adq: any) => adq.adquirenteid === selectedEstabelecimento.estabelecimentoadquirenteid);

      form.setFieldsValue({
        adquirente: selectedEstabelecimento.estabelecimentoadquirenteid,
        adquirenteNome: adquirente?.adquirentenome || 'Adquirente não encontrada',
        estabelecimentonumero: selectedEstabelecimento.estabelecimentonumero,
      });
    } else {
      console.log('Estabelecimento não encontrado');
    }
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();

      const payload = {
        header: {
          versao: '1.0',
          parceiro_id: 1,
          empresa_id: values.empresa,
          periodo_inicial: values.dataVenda.format('YYYY-MM-DD'),
          periodo_final: values.dataVenda.format('YYYY-MM-DD'),
          lote: `lote-${Date.now()}`,
        },
        transactions: [
          {
            codigo_identificador: values.codigoIdentificador ?? '',
            estabelecimento_numero: values.estabelecimentonumero,
            adquirente: values.adquirente,
            data_venda: values.dataVenda.format('YYYY-MM-DD'),
            data_previsao_pagamento: values.data_previsao_pagamento ?? null,
            numero_autorizacao: values.numero_autorizacao,
            numero_nsu: values.numero_nsu,
            numero_tid: values.numero_tid ?? '1212454',
            bandeira: Number(values.bandeira),
            valor_bruto_parcela: Number(values.valor_bruto_parcela),
            valor_liquido_parcela: values.valor_liquido_parcela ? Number(values.valor_liquido_parcela) : 0,
            valor_total: values.valor_total ? Number(values.valor_total) : 0,
            valor_desconto: values.valor_desconto ? Number(values.valor_desconto) : 0,
            taxa_venda: values.taxa_valor ? Number(values.taxa_valor) : 0,
            parcela: Number(values.parcela),
            total_parcelas: Number(values.total_parcela),
            numero_terminal: values.numero_terminal ?? '',
            meio_captura: Number(values.meio_captura) ?? 1,
            modalidade: Number(values.modalidade) ?? 1,
          },
        ],
      };

      const response = await enviarPagamentoApi(payload);

      notification.success({
        message: 'Sucesso!',
        description: 'Pagamento enviado com sucesso.',
      });

      setVisible(false);
    } catch (error) {
      if ((error as ValidationError).errorFields) {
        notification.error({
          message: 'Campos obrigatórios não preenchidos',
          description: 'Por favor, preencha todos os campos obrigatórios.',
        });
      } else {
        const errorMessage = error instanceof Error ? error.message : 'Ocorreu um erro ao enviar os dados.';
        notification.error({
          message: 'Erro ao enviar dados',
          description: errorMessage,
        });
      }
      throw new Error('Erro ao enviar pagamento');
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setVisible(true);
    handleClear();
  };
  const handleClose = () => {
    setVisible(false);
  };

  const handleClear = () => form.resetFields();

  return (
    <div>
      <ButtonInsertRecord onClick={handleOpen} icon={<PlusOutlined />} />
      <CustomModal open={visible} onCancel={handleClose} footer={null}>
        <div className="custom-modal-header">
          <img src="/static/media/finly.15a8555e6cb028e995c7334b1318fa17.svg" alt="Finly" className="modal-header-image" />
          <h2 className="modal-header-title">Inserir Dados</h2>
        </div>
        <div className="ant-modal-body-custom">
          <CustomConteinerFormModal form={form} layout="vertical">
            <h4>Informações da Transação</h4>
            <CustomConteinerFormModalDiv>
              <Form.Item label="Empresa" name="empresa" rules={[{ required: true, message: '' }]}>
                <Select placeholder="Selecione a empresa" onChange={handleChangeEmpresa}>
                  {empresa.length > 0 ? (
                    empresa.map((empresa) => (
                      <Select.Option key={empresa.empresaid} value={empresa.empresaid}>
                        {empresa.empresanome}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="" disabled>
                      Nenhuma empresa disponível
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item name="estabelecimento_id" label="Estabelecimento" rules={[{ required: true, message: '' }]}>
                <Select showSearch placeholder="Selecione o estabelecimento" optionFilterProp="children" onChange={handleChangeEstabelecimento} filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {estabelecimento !== undefined &&
                    estabelecimento.map((estab: any) => (
                      <Option key={estab.estabelecimentoid} value={estab.estabelecimentoid}>
                        {`${estab.estabelecimentonumero} - ${empresa.find((emp: any) => emp.empresaid === estab.estabelecimentoempresaid)?.empresanome || 'Empresa não encontrada'}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="estabelecimentonumero" noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                label="Código do Identificador"
                name="codigoIdentificador"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: 'Apenas letras e números são permitidos.',
                  },
                ]}
              >
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item label="Adquirente" name="adquirenteNome" rules={[{ required: true, message: '' }]}>
                <Input value={form.getFieldValue('adquirenteNome')} disabled />
              </Form.Item>
              <Form.Item name="adquirente" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                label="Data da Venda"
                name="dataVenda"
                style={{ whiteSpace: 'normal' }}
                rules={[
                  { required: true, message: '' },
                  ({}) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (value.isAfter(dayjs(), 'day')) {
                        return Promise.reject(new Error('A data da venda não pode ser futura!'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker style={{ width: '100%' }} placeholder="Selecione a data" />
              </Form.Item>
              <Form.Item
                label="Data Previsão Pagamento"
                name="data_previsao_pagamento"
                dependencies={['dataVenda']}
                style={{ whiteSpace: 'normal' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const dataVenda = getFieldValue('dataVenda');

                      if (!value || !dataVenda) {
                        return Promise.resolve();
                      }

                      const dataVendaDayjs = dayjs(dataVenda);
                      const dataPrevisaoDayjs = dayjs(value);

                      if (dataPrevisaoDayjs.isSameOrAfter(dataVendaDayjs, 'day')) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('A data de previsão não pode ser anterior à data da venda!'));
                    },
                  }),
                ]}
              >
                <DatePicker style={{ width: '100%' }} placeholder="Selecione a data" />
              </Form.Item>
              <Form.Item
                label="Número da Autorização"
                name="numero_autorizacao"
                style={{ whiteSpace: 'normal' }}
                rules={[
                  { required: true, message: '' },
                  { pattern: /^[a-zA-Z0-9]+$/, message: 'Apenas letras e números são permitidos.' },
                ]}
              >
                <Input maxLength={30} />
              </Form.Item>
            </CustomConteinerFormModalDiv>
            <h4>Detalhes da Transação</h4>
            <CustomConteinerFormModalDiv>
              <Form.Item
                label="Número da NSU"
                name="numero_nsu"
                style={{ whiteSpace: 'normal' }}
                rules={[
                  { required: true, message: '' },
                  { pattern: /^[0-9]+$/, message: 'Deve conter apenas dígitos!' },
                ]}
              >
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item
                label="Transaction ID"
                name="numero_tid"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: 'Apenas letras e números são permitidos.',
                  },
                ]}
              >
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item label="Bandeira" name="bandeira" rules={[{ required: true, message: '' }]}>
                <Select showSearch placeholder="Selecione a Bandeira" optionFilterProp="children" filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {bandeira.length > 0 ? (
                    bandeira.map((bandeira) => (
                      <Select.Option key={bandeira.bandeiraid} value={bandeira.bandeiraid}>
                        {bandeira.bandeiranome}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="" disabled>
                      Nenhuma bandeira disponível
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item label="Numero Terminal" name="numero_terminal" style={{ whiteSpace: 'normal' }} rules={[{ pattern: /^[0-9]+$/, message: 'Deve conter apenas dígitos!' }]}>
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item label="Meio Captura" name="meio_captura">
                <Select placeholder="Selecione o meio de captura">
                  {[
                    { id: 1, nome: 'Não informado' },
                    { id: 2, nome: 'POS' },
                    { id: 3, nome: 'Manual' },
                    { id: 4, nome: 'PDV/TEF' },
                    { id: 5, nome: 'QRCode' },
                    { id: 6, nome: 'Internet' },
                    { id: 7, nome: 'Outros' },
                  ].map((meio) => (
                    <Select.Option key={meio.id} value={meio.id}>
                      {meio.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Modalidade" name="modalidade">
                <Select showSearch placeholder="Selecione a Modalidade" optionFilterProp="children" filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {modalidade.length > 0 ? (
                    modalidade.map((modalidade) => (
                      <Select.Option key={modalidade.modalidadeid} value={modalidade.modalidadeid}>
                        {modalidade.modalidadenome}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="" disabled>
                      Nenhuma modalidade disponível
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </CustomConteinerFormModalDiv>
            <h4>Valores e Parcelamentos</h4>
            <CustomConteinerFormModalDiv>
              <Form.Item label="Valor Total" name="valor_total">
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  step={0.01}
                  formatter={(value) => {
                    if (value === undefined) return 'R$ 0,00';
                    let [integer, decimal] = value.toString().split('.');
                    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    decimal = decimal ? ',' + decimal.substring(0, 2) : '';
                    return `R$ ${integer}${decimal}`;
                  }}
                  parser={(value: string | undefined): number => {
                    if (!value) return 0;
                    const cleanedValue = value.replace(/R\$|\./g, '').replace(',', '.');
                    const parsedValue = Number(cleanedValue);

                    return isNaN(parsedValue) ? 0 : parsedValue;
                  }}
                />
              </Form.Item>
              <Form.Item label="Valor Bruto Parcela" name="valor_bruto_parcela" rules={[{ required: true, message: '' }]} style={{ whiteSpace: 'normal' }}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={1000000}
                  step={0.01}
                  precision={2}
                  formatter={(value) => {
                    if (value === undefined) return 'R$ 0,00';
                    let [integer, decimal] = value.toString().split('.');
                    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    decimal = decimal ? ',' + decimal.substring(0, 2) : '';
                    return `R$ ${integer}${decimal}`;
                  }}
                  parser={(value: string | undefined): number => {
                    if (!value) return 0;
                    const cleanedValue = value.replace(/R\$|\./g, '').replace(',', '.');
                    const parsedValue = Number(cleanedValue);

                    return isNaN(parsedValue) ? 0 : parsedValue;
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Valor Líquido Parcela"
                name="valor_liquido_parcela"
                style={{ whiteSpace: 'normal' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const valorBruto = getFieldValue('valor_bruto_parcela');

                      if (value && Number(value) > valorBruto) {
                        return Promise.reject(new Error('O valor líquido não pode ser maior que o valor bruto!'));
                      }
                      if (value && Number(value) > 1000000) {
                        return Promise.reject(new Error('O valor líquido não pode ser maior que R$ 1.000.000!'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={1000000}
                  step={0.01}
                  precision={2}
                  formatter={(value) => {
                    if (value === undefined) return 'R$ 0,00';
                    let [integer, decimal] = value.toString().split('.');
                    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    decimal = decimal ? ',' + decimal.substring(0, 2) : '';
                    return `R$ ${integer}${decimal}`;
                  }}
                  parser={(value: string | undefined): number => {
                    if (!value) return 0;
                    const cleanedValue = value.replace(/R\$|\./g, '').replace(',', '.');
                    const parsedValue = Number(cleanedValue);

                    return isNaN(parsedValue) ? 0 : parsedValue;
                  }}
                />
              </Form.Item>

              <Form.Item name="taxa_valor" label="Taxa %">
                <InputNumber
                  formatter={(value) => value?.toString().replace('.', ',') + '%' || ''}
                  parser={(value) => {
                    const parsed = value?.replace(/%\s?/g, '').replace(',', '.');
                    return parsed ?? '';
                  }}
                  onChange={(value) => {}}
                  precision={3}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item label="Valor Desconto" name="valor_desconto">
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  step={0.01}
                  formatter={(value) => {
                    if (value === undefined) return 'R$ 0,00';
                    let [integer, decimal] = value.toString().split('.');
                    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    decimal = decimal ? ',' + decimal.substring(0, 2) : '';
                    return `R$ ${integer}${decimal}`;
                  }}
                  parser={(value: string | undefined): number => {
                    if (!value) return 0;
                    const cleanedValue = value.replace(/R\$|\./g, '').replace(',', '.');
                    const parsedValue = Number(cleanedValue);

                    return isNaN(parsedValue) ? 0 : parsedValue;
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Parcela"
                name="parcela"
                style={{ whiteSpace: 'normal' }}
                rules={[
                  { required: true, message: '' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        if (Number(value) <= 0) {
                          return Promise.reject(new Error('O valor deve ser maior que 0!'));
                        }
                        if (Number(value) > Number(getFieldValue('total_parcela'))) {
                          return Promise.reject(new Error('A parcela não pode ser maior que o total de parcelas!'));
                        }
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input type="number" min={1} />
              </Form.Item>

              <Form.Item
                label="Total Parcela"
                name="total_parcela"
                style={{ whiteSpace: 'normal' }}
                rules={[
                  { required: true, message: '' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        if (Number(value) <= 0) {
                          return Promise.reject(new Error('O valor deve ser maior que 0!'));
                        }

                        if (Number(getFieldValue('parcela')) > Number(value)) {
                          return Promise.reject(new Error('A parcela não pode ser maior que o total de parcelas!'));
                        }
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input type="number" min={1} />
              </Form.Item>
            </CustomConteinerFormModalDiv>
            <CustomFooterButtonsContainer>
              <ButtonConcluir onClick={handleClear}>Limpar</ButtonConcluir>
              <ButtonConcluir onClick={handleOk} loading={loading}>
                Concluir
              </ButtonConcluir>
            </CustomFooterButtonsContainer>
          </CustomConteinerFormModal>
        </div>
      </CustomModal>
    </div>
  );
};

export default PopupInserirDados;
