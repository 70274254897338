import moment from 'moment';
import api from '../../../services/axios';
import { message } from 'antd';
import saveAs from 'file-saver';

export async function pagamentoChartDataApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/pagamento/pagamentoChartData';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoAuditAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/pagamento/pagamentoAuditAnalytics';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/pagamento/pagamentoChartAnalytics';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoTotalsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/pagamento/pagamentoTotals';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/pagamento/all';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoPdfApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/pagamento/export/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_pagamento';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/pdf') {
            fileName += '.pdf';
        } else {
            fileName += '.bin'; 
        }
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName);        
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}

export async function pagamentoXlsApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/pagamento/export/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        });
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_pagamento';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fileName += '.xlsx';
        } else {
            fileName += '.bin';
        }
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, fileName);
    } catch (error) {
        message.error(`Erro ao baixar o arquivo XLSX: ${error}`);
    }
}

export async function pagamentoAuditadoApiXls(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/pagamento/export-audit/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        });
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_pagamentos_auditados';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fileName += '.xlsx';
        } else {
            fileName += '.bin';
        }
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName);
    } catch (error) {
        message.error(`Erro ao baixar o arquivo auditado: ${error}`);
    }
}


export async function pagamentoAuditadoApiPdf(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/pagamento/export-audit/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_pagamentos_auditados';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/pdf') {
            fileName += '.pdf';
        } else {
            fileName += '.bin'; 
        }
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName); 
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}

export async function pagamentoConsolidadoApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/pagamento-consolidado/all';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoConsolidadoFiltroApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 100000, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/pagamento-consolidado/all';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function pagamentoConsolidadoPdfApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/pagamento-consolidado/export/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        const contentType = response.headers['content-type'];
        let fileName = 'pagamento_consolidado';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/pdf') {
            fileName += '.pdf';
        } else {
            fileName += '.bin'; 
        }
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName); 
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}

export async function pagamentoConsolidadoXlsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/pagamento-consolidado/export/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });

    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }

    const url = `${baseURL}?${params.toString()}`;

    const controller = new AbortController();
    const signal = controller.signal;
    const handleLogout = () => {
        controller.abort();
    };

    window.addEventListener('logout', handleLogout);

    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
            signal,
        });
        window.removeEventListener('logout', handleLogout);

        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_pagamento_consolidado';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fileName += '.xlsx';
        } else {
            fileName += '.bin';
        }

        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName);
    } catch (error: any) {
        if (error.name === 'AbortError') {
            console.warn('Download cancelado devido ao logout.');
        } else {
            message.error(`Erro ao baixar o arquivo XLS: ${error}`);
        }
    } finally {
        window.removeEventListener('logout', handleLogout);
    }
}
