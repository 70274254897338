import React, { useEffect, useState } from 'react';
import { AppLaunchBanner, MinimizeButton, StyledTitle, StyledParagraph } from './styled';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import animationData from '../../assets/dashboard/alert.json';
import Lottie from 'react-lottie';

interface AppBannerProps {
  onToggle: () => void;
  isMinimized: boolean;
  notice: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const AppBanner: React.FC<AppBannerProps> = ({ onToggle }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMinimized(true);
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
    onToggle();
  };

  return (
    <AppLaunchBanner isMinimized={isMinimized}>
      <MinimizeButton icon={isMinimized ? <PlusOutlined /> : <MinusOutlined />} onClick={handleToggle} isMinimized={isMinimized} />
      {!isMinimized && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Lottie options={defaultOptions} height={80} width={80} />
          </div>
          <StyledTitle level={3}>Finly Card News</StyledTitle>
          <StyledParagraph>Notícias e novidades diretamente na sua tela principal! Esteja atento à todas novidades da Finly visando melhorar sua experiência como cliente!</StyledParagraph>
        </>
      )}
    </AppLaunchBanner>
  );
};
