import { Button, Image, Table, Tag, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import moment from 'moment';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens } from '../../../styles/globalInterfaces';
import { round } from 'lodash';

const ExpandedRowRender = ({ record }: { record: any }) => {
  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);
  const [currentPagePayment, setCurrentPagePayment] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);
  const [resetAnimation, setResetAnimation] = useState(false);

  useEffect(() => {
    setResetAnimation(true);
    setTimeout(() => setResetAnimation(false), 100);
    // console.log('aqui', record)
  }, [currentPageTransaction, isExpanded]);

  const animationProps = useSpring({
    reset: resetAnimation,
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  });

  const columnsTransacoes = [
    {
      title: 'Data Depósito',
      dataIndex: 'ofxdatadeposito',
      key: 'ofxdatadeposito',
      render: (text: any) => (text ? moment(text).format('DD/MM/YYYY') : '---'),
      width: 150,
      align: 'center' as const,
    },
    {
      title: 'Descrição Depósito',
      dataIndex: 'ofxtransacaodescricaodeposito',
      key: 'ofxtransacaodescricaodeposito',
      sorter: false,
      render: (text: any) => text,
    },
    {
      title: 'Valor',
      dataIndex: 'ofxtransacaovalordeposito',
      key: 'ofxtransacaovalordeposito',
      sorter: false,
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
    },
    {
      title: 'Status Conciliação',
      dataIndex: 'ofxtransacaostatusconciliacao',
      key: 'ofxtransacaostatusconciliacao',
      sorter: false,
      render: (text: any) => (text ? 'Conciliado' : 'Não Conciliado'),
    },
  ];

  const columnsPagamentos = [
    {
      title: 'Data Venda',
      dataIndex: 'pagamentodatavenda',
      key: 'pagamentodatavenda',
      render: (text: any) => (text ? moment(text).format('DD/MM/YYYY') : '---'),
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Data Pagamento',
      dataIndex: 'pagamentodatapagamento',
      key: 'pagamentodatapagamento',
      render: (text: any) => (text ? moment(text).format('DD/MM/YYYY') : '---'),
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'estabelecimentonumero',
      key: 'estabelecimentonumero',
      render: (text: string, record: any) => `${text}`,
      width: 180,
      align: 'center' as const,
    },
    {
      title: 'Adquirente',
      dataIndex: 'adquirentenome',
      key: 'adquirentenome',
      render: (text: string, record: any) => <Image preview={false} width={35} src={adquirenteNomeParaImagem[record.adquirentenome.toLowerCase().replace(/\s/g, '')]} alt={record.adquirentenome} />,
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Bandeira',
      dataIndex: 'bandeiranome',
      key: 'bandeiranome',
      render: (text: string, record: any) => (
        <Tooltip title={record.bandeiranome} placement="left">
          <div>
            <Image width={25} src={bandeiraNomeParaImagens[record.bandeiranome.toLowerCase()]} alt={record.bandeiranome} preview={false} />
          </div>
        </Tooltip>
      ),
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Autorização',
      dataIndex: 'pagamentoautorizacao',
      key: 'pagamentoautorizacao',
      render: (text: string, record: any) => text,
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Valor Bruto',
      dataIndex: 'pagamentovalorbruto',
      key: 'pagamentovalorbruto',
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Taxa',
      dataIndex: 'pagamentotaxa',
      key: 'pagamentotaxa',
      render: (text: string, record: any) => (
        <div>
          <span>{record.pagamentotaxaantecipado > 0 ? round(parseFloat(record.pagamentotaxa) + parseFloat(record.pagamentotaxaantecipado), 2).toFixed(2) : round(record.pagamentotaxa, 2).toFixed(2)}%</span>
        </div>
      ),
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Valor Líquido',
      dataIndex: 'pagamentovalorliquido',
      key: 'pagamentovalorliquido',
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidadenome',
      key: 'modalidadenome',
      render: (text: string, record: any) => <Tag>{record.modalidadenome}</Tag>,
      width: 120,
      align: 'center' as const,
    },
  ];

  const handleTableChangeTransactions = (newPage: number) => {
    setCurrentPageTransaction(newPage);
  };


  const handleTableChangePayments = (newPage: number) => {
    setCurrentPagePayment(newPage);
  };
  const paginatedPagamentosData = record.pagamentos.slice((currentPagePayment - 1) * pageSize, currentPagePayment * pageSize);
  const paginatedTransacoesData = record.transacoes?.slice((currentPageTransaction - 1) * pageSize, currentPageTransaction * pageSize);

  return (
    <animated.div style={animationProps}>
      {/* Renderiza a tabela de transações apenas se houver transações */}
      {record.transacoes && record.transacoes.length > 0 && (
        <>
          <div style={{ width: '97%', margin: '0 auto', borderBottom: '2px solid #fff' }}>
            <h3 style={{ marginLeft: 20 }}>Transações</h3>
            <Table columns={columnsTransacoes} dataSource={paginatedTransacoesData} pagination={false} rowKey="ofxtransacaoid" showHeader={true} />
            {/* Paginação customizada */}
            <div style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}>
              <Button onClick={() => handleTableChangeTransactions(currentPageTransaction - 1)} disabled={currentPageTransaction === 1} style={{ marginRight: 10 }}>
                Anterior
              </Button>
              <Button onClick={() => handleTableChangeTransactions(currentPageTransaction + 1)} disabled={(currentPageTransaction - 1) * pageSize + paginatedTransacoesData.length >= record.transacoes.length}>
                Próximo
              </Button>
            </div>
          </div>
        </>
      )}

      {/* Tabela de pagamentos sempre renderiza */}
      {record.pagamentos && record.pagamentos.length > 0 && (
        <>
          <div style={{ width: '97%', margin: '0 auto', borderBottom: '2px solid #fff' }}>
            <h3 style={{ marginBottom: 10, marginLeft: 20 }}>Pagamentos Conciliados</h3>
            <Table columns={columnsPagamentos} dataSource={paginatedPagamentosData} pagination={false} rowKey="ofxtransacaoid" showHeader={true} />
            {/* Paginação customizada */}
            <div style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}>
              <Button onClick={() => handleTableChangePayments(currentPagePayment - 1)} disabled={currentPagePayment === 1} style={{ marginRight: 10 }}>
                Anterior
              </Button>
              <Button onClick={() => handleTableChangePayments(currentPagePayment + 1)} disabled={(currentPagePayment - 1) * pageSize + paginatedPagamentosData.length >= record.pagamentos.length}>
                Próximo
              </Button>
            </div>
          </div>
        </>
      )}
    </animated.div>
  );
};

export default ExpandedRowRender;
