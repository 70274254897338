import { Button, Image, Table, Tag, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import moment from 'moment';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens } from '../../../styles/globalInterfaces';
import { round } from 'lodash';

const ExpandedRowRender = ({ record }: { record: any }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);
  const [resetAnimation, setResetAnimation] = useState(false);

  useEffect(() => {
    setResetAnimation(true);
    setTimeout(() => setResetAnimation(false), 100);
  }, [currentPage, isExpanded]);

  const animationProps = useSpring({
    reset: resetAnimation,
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' }
  });

  const columns = [
    {
      title: 'Data Venda',
      dataIndex: 'pagamentodatavenda',
      key: 'pagamentodatavenda',
      render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : '---',
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Data Pagamento',
      dataIndex: 'pagamentodatapagamento',
      key: 'pagamentodatapagamento',
      render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : '---',
      width: 120,
      align: 'center' as const,
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'estabelecimentonumero',
      key: 'estabelecimentonumero',
      sorter: true,
      render: (text: string, record: any) => `Estabelecimento: ${text}`,
      width: 250,
      align: 'center' as const
    },
    {
      title: 'Adquirente',
      dataIndex: 'adquirentenome',
      key: 'adquirentenome',
      sorter: true,
      render: (text: string, record: any) => <Image preview={false} width={35} src={adquirenteNomeParaImagem[record.adquirentenome.toLowerCase().replace(/\s/g, '')]} alt={record.adquirentenome} />,
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Bandeira',
      dataIndex: 'bandeiranome',
      key: 'bandeiranome',
      sorter: true,
      render: (text: string, record: any) => (
        <Tooltip title={record.bandeiranome} placement="left">
          <div>
            <Image width={25} src={bandeiraNomeParaImagens[record.bandeiranome.toLowerCase()]} alt={record.bandeiranome} preview={false} />
          </div>
        </Tooltip>
      ),
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Autorização',
      dataIndex: 'pagamentoautorizacao',
      key: 'pagamentoautorizacao',
      render: (text: string, record: any) => text,
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Valor Bruto',
      dataIndex: 'pagamentovalorbruto',
      key: 'pagamentovalorbruto',
      render: (value: number) => `Bruto - R$ ${value.toFixed(2)}`,
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Taxa',
      dataIndex: 'pagamentotaxa',
      key: 'pagamentotaxa',
      sorter: true,
      render: (text: string, record: any) => (
        <div>
          <span>
            Taxa - {record.pagamentotaxaantecipado > 0 ? round(parseFloat(record.pagamentotaxa) + parseFloat(record.pagamentotaxaantecipado), 2).toFixed(2) : round(record.pagamentotaxa, 2).toFixed(2)}%
          </span>
        </div>
      ),
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Valor Líquido',
      dataIndex: 'pagamentovalorliquido',
      key: 'pagamentovalorliquido',
      render: (value: number) => `Líquido - R$ ${value.toFixed(2)}`,
      width: 120,
      align: 'center' as const
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidadenome',
      key: 'modalidadenome',
      sorter: true,
      render: (text: string, record: any) => <Tag>{record.modalidadenome}</Tag>,
      width: 120,
      align: 'center' as const
    }
  ];

  const handleTableChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const paginatedData = record.transacoes.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <animated.div style={animationProps}>
      {/* Tabela expandida */}
      <Table
        columns={columns}
        dataSource={paginatedData}
        pagination={false}
        rowKey="ofxtransacaoid"
        showHeader={false}
      />
      {/* Paginação customizada */}
      <div style={{ marginTop: 10, textAlign: 'center' }}>
        <Button onClick={() => handleTableChange(currentPage - 1)} disabled={currentPage === 1} style={{ marginRight: 10 }}>
          Anterior
        </Button>
        <Button
          onClick={() => handleTableChange(currentPage + 1)}
          disabled={(currentPage - 1) * pageSize + paginatedData.length >= record.transacoes.length}
        >
          Próximo
        </Button>
      </div>
    </animated.div>
  );
};

export default ExpandedRowRender;
