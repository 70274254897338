import styled from 'styled-components';
import BackgroundImage from '../../assets/landing-page/background_landing_page.png';
import { Button, Col } from 'antd';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  background-image: url('${BackgroundImage}');
  background-repeat: no-repeat;
  margin: 0 auto;
  @media (max-width: 1669px), (max-height: 667px) {
    background-image: none;
  }
`;

export const LoginButton = styled(Button)`
  position: absolute;
  margin-top: -5%;
  left: 90%;
  transform: translate(-50%, -50%) !important;
  font-size: 120%;
  padding: 0 15px;
  background-color: #c9e437 !important;
`;

export const LoginProposal = styled(Button)`
  background-color: #c9e437;
  @media (max-width: 1669px), (max-height: 667px) {
    position: relative;
    margin-top: 0%;
  }
`;

export const PositiontFooter = styled.div`
  @media (max-width: 1669px), (max-height: 667px) {
    img {
      display: none;
    }
  }
`;

export const LeftScreen = styled.div`
  @media (max-width: 1669px), (max-height: 667px) {
    margin-top: 5%;
    padding: 0 10%;
    margin-left: -10%;
  }

  @media (max-width: 1250px), (max-height: 667px) {
    margin-left: -20%;
    margin-top: -10%;
    word-wrap: break-word;
    padding: 0 20%;
  }

  @media (max-width: 430px), (max-height: 667px) {
    margin-top: -40%;
    position: relative;
    margin-left: -30%;
    padding: 0 10%;
  }
`;

export const StyledGroupFinlyImage = styled.div`
  @media (max-width: 540px), (max-height: 667px) {
    img {
      margin-top: 10%;
    }
  }
`;

export const ContainerPositionScreen = styled(Col)`
  @media (max-width: 1669px), (max-height: 667px) {
    position: relative;
    margin-top: -40%;
    left: -5%;
  }

  @media (max-width: 1250px) {
    display: none;
  }
`;
