import { Menu } from 'antd';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { DropDownContentProps } from './interfaces';
import ButtonExportXlsPdf from '../Common/ButtonExportXlsPdf';
import { StyledDropdown } from './styled';

export function DropDownContent({ loadingExport, transacoes, handleExportXls, handleExportPdf }: DropDownContentProps) {
  const exportMenu = (
    <Menu>
      <Menu.Item key="xlsx" onClick={handleExportXls}>
        <FileExcelOutlined style={{ marginRight: 10 }} />XLSX
      </Menu.Item>
      <Menu.Item key="pdf" onClick={handleExportPdf}>
        <FilePdfOutlined style={{ marginRight: 10 }} /> PDF
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <StyledDropdown overlay={exportMenu} trigger={['click']} placement="bottomRight">
        <ButtonExportXlsPdf icon={<DownloadOutlined />} loading={loadingExport} disabled={transacoes.length === 0} onClick={() => { }} />
      </StyledDropdown>
    </div>
  );
}
