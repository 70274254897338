import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 16px;
  }
`;

export const StyledCardBancaria = styled(Card)`
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 16px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
  }
  `;


export const RefreshButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ececec;
  margin-bottom: 5px;
  border-radius: 8px;
  width: 100%;

  .item {
    display: flex;
    flex-basis: calc(100% / 6);
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
  }

  .item-title, .item-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-title {
    font-weight: bold;
    color: #333;
    text-align: left;
    width: 10%;
  }

  .item-value {
    color: #3b5998;
    text-align: right;
    width: 35%;
    margin-right: 20px;
  }
`;
