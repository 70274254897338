import { Button, Image, Select, Tag, Tooltip } from 'antd';
import { Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens, modalidades } from '../../../styles/globalInterfaces';
import moment from 'moment';

const { Option } = Select;

export const columnsPagamentoConsolidado = (adquirentes: any[], bandeiras: any[], empresas: any[], contas: any[], selectedAdquirentes?: any[]): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Empresa',
      dataIndex: 'EMPRESANOME',
      key: 'EMPRESANOME',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select allowClear placeholder="Selecione a empresa" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {empresas.map((estab: any) => (
              <Option key={estab[0]} value={estab}>
                {estab}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      render: (text, record) => text,
    },
    {
      title: 'Data Pagamento',
      dataIndex: 'PAGAMENTODATAPAGAMENTO',
      key: 'PAGAMENTODATAPAGAMENTO',
      sorter: true,
      render: (text: any) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Conta Bancária',
      dataIndex: 'CONTABANCARIA',
      key: 'CONTABANCARIA',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select allowClear placeholder="Selecione a conta" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {contas.map((estab: any) => (
              <Option key={estab[0]} value={estab}>
                {estab}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      render: (text, record) => text,
    },
    {
      title: 'Adquirente',
      dataIndex: 'ADQUIRENTENOME',
      key: 'ADQUIRENTENOME',
      sorter: true,
      render: (text: string, record: any) => <Image preview={false} width={35} src={adquirenteNomeParaImagem[record.ADQUIRENTENOME.toLowerCase().replace(/\s/g, '')]} alt={record.ADQUIRENTENOME} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Selecione a adquirente"
            style={{ width: 200, marginBottom: 8, display: 'block' }}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            disabled={selectedAdquirentes ? selectedAdquirentes.length > 0 : false}
          >
            {adquirentes.map((adq: any) => (
              <Option key={adq.adquirenteid} value={adq.adquirentenome}>
                {adq.adquirentenome}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
            disabled={selectedAdquirentes ? selectedAdquirentes.length > 0 : false}
          >
            Buscar
          </Button>
          <Button
            disabled={selectedAdquirentes ? selectedAdquirentes.length > 0 : false}
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.ADQUIRENTENOME);
      }
    },
    {
      title: 'Bandeira',
      dataIndex: 'BANDEIRANOME',
      key: 'BANDEIRANOME',
      sorter: true,
      render: (text: string, record: any) => (
        <Tooltip title={record.BANDEIRANOME} placement="left">
          <div>
            <Image width={25} src={bandeiraNomeParaImagens[record.BANDEIRANOME.toLowerCase()]} alt={record.BANDEIRANOME} preview={false} />
          </div>
        </Tooltip>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Selecione a bandeira"
            style={{ width: 200, marginBottom: 8, display: 'block' }}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {bandeiras.map((bandeira: any) => (
              <Option key={bandeira.bandeiraid} value={bandeira.bandeiranome}>
                {bandeira.bandeiranome}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.BANDEIRANOME);
      }
    },
    {
      title: 'Modalidade',
      dataIndex: 'MODALIDADENOME',
      key: 'MODALIDADENOME',
      sorter: true,
      render: (text: string, record: any) => <Tag>{record.MODALIDADENOME}</Tag>,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Selecione a modalidade"
            style={{ width: 200, marginBottom: 8, display: 'block' }}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {modalidades.map((modalidade: any) => (
              <Option key={modalidade.id} value={modalidade.name}>
                {modalidade.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.MODALIDADENOME);
      }
    },
    {
      title: 'Valor Líquido',
      dataIndex: 'PAGAMENTOVALORLIQUIDO',
      key: 'PAGAMENTOVALORLIQUIDO',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        const formattedValue = value.replace('R$', '').trim().replace(/\./g, '').replace(',', '.');
        const numericValue = parseFloat(record.PAGAMENTOVALORLIQUIDO); 
        return numericValue === parseFloat(formattedValue);
      },  
      render: (value: any) => (
        <div>
          <span>{parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
        </div>
      ),
    },
  ];
  return columns;
};
