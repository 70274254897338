import axios from 'axios';
import store from '../store';
import { setAccessToken } from '../store/states/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      store.dispatch(setAccessToken(null, null));
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use((config) => {
  const { accessToken } = store.getState().auth;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default api;
