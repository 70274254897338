import React from 'react';
import { ButtonInsertRecordStyled } from './styled';



interface ButtonInsertRecord {
    onClick: () => void;
    icon: React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
}

const ButtonInsertRecord: React.FC<ButtonInsertRecord> = ({ onClick, icon, loading, disabled }) => {
    return (
        <ButtonInsertRecordStyled onClick={onClick} icon={icon} loading={loading} disabled={disabled}>
            Inserir Registros
        </ButtonInsertRecordStyled>
    );
};

export default ButtonInsertRecord;
