import { CLEAR_EMPRESA_DATA, FETCH_EMPRESA_DATA, FETCH_EMPRESA_FAILURE, FETCH_EMPRESA_SUCCESS, UPDATE_EMPRESA_ID } from './types';

export const clearCompanyData = () => ({
  type: CLEAR_EMPRESA_DATA,
});

export const fetchCompany = (payload: { user_id: number }) => ({
  type: FETCH_EMPRESA_DATA,
  payload,
});

export const fetchCompanySuccess = (data: any[]) => ({
  type: FETCH_EMPRESA_SUCCESS,
  data,
});

export const fetchCompanyFailure = (error: any) => ({
  type: FETCH_EMPRESA_FAILURE,
  payload: error,
});

export const updateCompanyID = (companyIDs: number[]) => ({
  type: UPDATE_EMPRESA_ID,
  payload: companyIDs,
});
